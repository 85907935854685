import { Spinner, Table } from '@campxdev/shared'
import { Box, Button, Typography } from '@mui/material'
import { useState } from 'react'
import { useQuery } from 'react-query'
import { SERVICES } from 'services'
import * as XLSX from 'xlsx'

export const FailedMessages = ({
  clientName,
  today,
}: {
  clientName: string
  today?: boolean
}) => {
  const [filters, setFilters] = useState({
    search: '',
    limit: 10,
    offset: 0,
  })

  const { data, isLoading } = useQuery(
    ['failed-messages', filters],
    () =>
      SERVICES.analytics.fetchFailedMessages({
        clientName,
        today,
        offset: filters.offset,
        limit: filters.limit,
      }),
    {
      cacheTime: 0,
    },
  )

  const handlePagination = (value: number) => {
    setFilters({
      ...filters,
      offset: value * filters.limit - filters.limit,
    })
  }
  const handlePageLimit = (value: number) => {
    setFilters((prev) => ({
      ...prev,
      limit: value,
      offset: 0,
    }))
  }

  async function handleExport() {
    const excelData = data.map((item) => ({
      'User Id': item.userId,
      'User Name': item.username.toUpperCase(),
      'Phone Number': item.phoneNumber,
      Status: item.status,
    }))

    const fileName = `failedmessages - ${new Date()
      .toISOString()
      .slice(0, 10)}.xlsx`

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'test')
    XLSX.writeFile(wb, fileName)
  }

  if (isLoading) return <Spinner />
  return (
    <>
      <Box sx={{ textAlign: 'right' }}>
        <Button
          variant="outlined"
          sx={{ marginBottom: '10px' }}
          onClick={() => handleExport()}
        >
          Export
        </Button>
      </Box>
      <Table
        columns={FailedMessagesColumn}
        dataSource={data.result}
        pagination={{
          page: filters.offset / filters.limit,
          totalCount: data?.count ?? 0,
          limit: filters.limit,
          onChange: handlePagination,
          onChangeLimit: handlePageLimit,
        }}
      />
    </>
  )
}

const FailedMessagesColumn = [
  {
    title: 'User Id',
    dataIndex: 'userId',
    key: 'college',
  },
  {
    title: 'User Name',
    dataIndex: 'username',
    key: 'userName',
    render: (_, row) => row.username.toUpperCase(),
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (_, row) => {
      return <Typography sx={{ color: 'red' }}>{row.status}</Typography>
    },
  },
]
