import {
  ActionButton,
  DrawerButton,
  EditButton,
  PageContent,
  PageHeader,
  Table,
} from '@campxdev/shared'
import { useQuery } from 'react-query'
import { useImmer } from 'use-immer'
import AppFilters from './AppFilters'
import AppForm from './AppForm'
import { fetchData } from './services'
const columns = [
  {
    title: 'College Name',
    dataIndex: 'collegeName',
    key: 'collegeName',
  },
  {
    title: 'App Name',
    dataIndex: 'appName',
    key: 'appName',
    render: (_, row) => {
      return row.appName == 'campx-student-app' ? 'Student App' : 'Faculty App'
    },
  },
  {
    title: 'Sub Domain',
    dataIndex: 'tenant.subDomain',
    key: 'tenant.subDomain',
  },
  {
    title: 'IOS AppId',
    dataIndex: 'iosAppId',
    key: 'iosAppId',
    render: (_, row) => {
      return row.iosAppId ? row.iosAppId : <span>---</span>
    },
  },
  {
    title: 'Android AppId',
    dataIndex: 'androidAppId',
    key: 'androidAppId',
    render: (_, row) => {
      return row.androidAppId ? row.androidAppId : <span>---</span>
    },
  },
  {
    title: 'Actions',
    dataIndex: 'actions',
    key: 'actions',
    render: (_, row) => {
      return (
        <div style={{ display: 'flex', gap: 10 }}>
          <DrawerButton
            title="Edit Groupe"
            anchor={({ open }) => <EditButton onClick={open} />}
            content={({ close }) => <AppForm data={row} hideDialog={close} />}
          />
        </div>
      )
    },
  },
]
export const defaultObj = {
  page: 0,
  limit: 10,
  data: [],
}
function Apps() {
  const [state, setState] = useImmer(defaultObj)
  const [filters, setFilters] = useImmer({
    appName: '',
    tenantId: '',
  })
  const { data, isLoading: loadingfetch } = useQuery(
    ['fetch-apps', filters.appName, filters.tenantId],
    () => fetchData(filters),
    {
      onSuccess: (res) => {
        setState((s) => {
          s.data = res?.themes?.slice(0, 10)
        })
      },
    },
  )
  const handlePagination = (value: number) => {
    setState((s) => {
      s.page = value - 1
      s.data = data?.themes?.slice((value - 1) * s.limit, value * s.limit)
    })
  }
  const handlePageLimit = (value: number) => {
    setState((s) => {
      s.limit = value
      s.data = data?.themes?.slice(0, value)
    })
  }

  return (
    <>
      <PageHeader
        title={'Mobile Apps'}
        actions={
          <DrawerButton
            title="Add new  App"
            anchor={({ open }) => (
              <ActionButton onClick={open}>Add New</ActionButton>
            )}
            content={({ close }) => <AppForm data={null} hideDialog={close} />}
          />
        }
      />
      <PageContent>
        <AppFilters setFilters={setFilters} />
        <Table
          columns={columns}
          dataSource={state?.data || []}
          pagination={{
            totalCount: data?.count || 0,
            page: state.page,
            limit: state.limit,
            onChange: handlePagination,
            onChangeLimit: handlePageLimit,
          }}
        />
      </PageContent>
    </>
  )
}

export default Apps
