import {
  ActionButton,
  FormTextField,
  Media,
  MediaRow,
  UploadButton,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import axios from 'config/axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import * as yup from 'yup'

const schema = yup.object().shape({
  title: yup.string().required('title is required'),
  description: yup.string().required('Description  is required'),
})

export default function CategoryTipsForm({
  data,
  hideDialog,
}: {
  data?: any
  hideDialog: () => void
}) {
  const { id } = useParams()

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
    },
  })
  const queryClient = useQueryClient()
    const handleDelte = (mediaKey) => {
      setLogo(logo?.filter((item) => item.key !== mediaKey))
    }
  const [logo, setLogo] = useState<Media[]>([])
  useEffect(() => {
    if (!data) return
    setLogo([
      {
        key: data?.imageKey,
        url: data?.imageKey,
        type: 'image',
      },
    ])
  }, [data])

  const handleLogoChange = (mediaObj) => {
    setLogo((prev) => [...prev, mediaObj])
  }

  const onSubmit = async (formdata) => {
    const postBody = {
      title: formdata.title,
      description: formdata.description,
      imageKey: logo[0].url,
    }
    const config: AxiosRequestConfig = {
      method: data ? 'PUT' : 'POST',
      url: data
        ? `/tips-categories/${id}/tips/${data._id}`
        : `/tips-categories/${id}/tips`,

      data: postBody,
    }
    try {
      await axios(config)
      hideDialog()
      toast.success(
        data ? 'Tips Updated Successfully' : 'Tips created Successfully',
      )
      queryClient.invalidateQueries('categoriesData')
    } catch (error) {
      toast.error(error.response.data.message ?? 'Error saving Tips')
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2.5}>
        <FormTextField
          name="title"
          label={'Title'}
          control={control}
          required
        />
        <FormTextField
          control={control}
          name="description"
          label={'Description'}
          required
        />
        <Typography variant="subtitle1" sx={{ paddingLeft: '5px' }}>
          Image
        </Typography>
        {!logo?.length ? (
          <UploadButton
            type="image"
            onChange={handleLogoChange}
            uploadUrl="/tips-categories/upload-image"
            refetchFn={()=>{}
          }
           
          />
        ) : (
          <>
            <MediaRow list={logo} onDelete={handleDelte} />
          </>
        )}
      </Stack>
      <Stack direction="row" gap={2} mt={2}>
        <ActionButton type="submit" fullWidth>
          {data ? 'Add Tips' : 'Add Tips'}
        </ActionButton>
        <ActionButton variant="outlined" fullWidth onClick={() => hideDialog()}>
          Cancel
        </ActionButton>
      </Stack>
    </form>
  )
}
