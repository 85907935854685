import moment from 'moment'
import * as XLSX from 'xlsx'

export const exportReport = (state) => {
  const worksheet = XLSX.utils.json_to_sheet(
    state.data.map((item) => {
      let formattedItem = {}
      state.headers.forEach((header) => {
        const formattedHeader = header
          .toLowerCase()
          .split('_')
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ')
        formattedItem[formattedHeader] = item[header]
      })
      return formattedItem
    }),
  )

  const workbook = XLSX.utils.book_new()

  // Create a dynamic name for the sheet based on fromDate and toDate
  const sheetName = `${moment(state.fromDate).format('DD-MM-YYYY')} - ${moment(
    state.toDate,
  ).format('DD-MM-YYYY')}`
  XLSX.utils.book_append_sheet(workbook, worksheet, sheetName)

  XLSX.writeFile(workbook, 'AcademicTimetableReport.xlsx')
}
