import {
  FormSingleCheckbox,
  FormSingleSelect,
  RenderForm,
} from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { Button, Stack } from '@mui/material'
import { configTypes, paymentTypes } from 'constants/paymentTypes'
import { fetchFeeCharges } from 'pages/PaymentFeeCharges/service'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'

function PaymentTypeForm({
  data,
  tenantId,
  hideDialog,
  defaultId,
}: {
  data?: any
  tenantId?: any
  hideDialog?: any
  defaultId?: any
}) {
  const [type, setType] = useState(null)
  const [production, setProduction] = useState(false)

  const { data: paymentCharges, isLoading } = useQuery(
    'get-payment-fee-charges',
    () => fetchFeeCharges(tenantId),
  )

  const { handleSubmit, control, reset, setValue } = useForm({
    defaultValues: {
      paymentFeeChargesId: data?.paymentFeeChargesId || '',
    },
  })
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (!!data) {
      if (!!data[configTypes[data?.type]]) {
        if (defaultId == data?._id) {
          setChecked(true)
        }
        if (data?.paytmConfig?.isProd) {
          setProduction(true)
        }
        ConfigFields[data?.type].map((item) => {
          setValue(data.type, data[configTypes[data.type]])
        })
      } else {
        reset()
      }
    }
  }, [data])

  async function onSubmit(formData) {
    formData.type = type ? type : data?.type
    formData.setAsDefault = checked
    let postBody = {
      type: formData.type,
      setAsDefault: formData.setAsDefault,
      paymentFeeChargesId: formData.paymentFeeChargesId,
    }
    postBody[configTypes[formData.type]] = formData[formData.type]

    try {
      await SERVICES.paymentTypes.createType(tenantId, data, postBody)
      queryClient.invalidateQueries('payment-types')
      hideDialog()
      data
        ? toast.success('Payment Credential Updated successfully')
        : toast.success('Payment Credential Created successfully')
    } catch (error) {
      toast.error('Error creating Payment Credentials')
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          {!data ? (
            <>
              <FormSingleSelect
                name="type"
                control={control}
                options={[
                  { label: 'Paytm', value: paymentTypes.PAYTM },
                  { label: 'Razor Pay', value: paymentTypes.RAZORPAY },
                  { label: 'Air Pay', value: paymentTypes.AIRPAY },
                  { label: 'Bill Desk', value: paymentTypes.BILL_DESK },
                  {
                    label: 'Bill Desk V2',
                    value: paymentTypes.BILL_DESK_V2,
                  },
                  {
                    label: 'Gray Quest',
                    value: paymentTypes.GRAY_QUEST,
                  },
                ]}
                label="Select Type"
                required
                onChange={(e) => setType(e.target.value)}
              />
              {type && (
                <RenderForm
                  fields={ConfigFields[type]?.map((item) => ({
                    ...item,
                    name: `${type}.${item.name}`,
                  }))}
                  cols={1}
                  control={control}
                />
              )}
            </>
          ) : (
            <>
              <RenderForm
                fields={ConfigFields[type ? type : data.type]?.map((item) => ({
                  ...item,
                  name: `${data.type}.${item.name}`,
                }))}
                cols={1}
                control={control}
              />
            </>
          )}

          <FormSingleCheckbox
            label={'Is Production'}
            name={'isProduction'}
            control={control}
            checked={production}
            onChange={(e) => setProduction(!production)}
          />

          <FormSingleCheckbox
            label={'Default Payment'}
            name={'setAsDefault'}
            control={control}
            checked={checked}
            onChange={(e) => setChecked(!checked)}
          />
          <FormSingleSelect
            control={control}
            name="paymentFeeChargesId"
            options={paymentCharges?.map((item) => {
              return {
                label: item.name,
                value: item.id,
              }
            })}
            label={'Select Payment Fee Charges'}
          />
          <Button type="submit">Submit</Button>
        </Stack>
      </form>
    </>
  )
}

export default PaymentTypeForm

export const ConfigFields: any = {
  PAYTM: [
    {
      name: 'merchantId',
      render: 'FormTextField',
      required: true,
      label: 'Merchant Id',
    },
    {
      name: 'merchantKey',
      render: 'FormTextField',
      required: true,
      label: 'Merchant Key',
    },
    {
      name: 'industryType',
      render: 'FormTextField',
      required: true,
      label: 'Industry Type',
    },
    {
      name: 'website',
      render: 'FormTextField',
      required: true,
      label: 'Website',
    },
    {
      name: 'channelId',
      render: 'FormTextField',
      required: true,
      label: 'Channel Id',
    },
    {
      name: 'callbackUrl',
      render: 'FormTextField',
      required: false,
      label: 'Callback Url',
    },
    {
      name: 'transactionUrl',
      render: 'FormTextField',
      required: true,
      label: 'Transaction Url',
    },
    {
      name: 'accountName',
      render: 'FormTextField',
      required: true,
      label: 'Account Name',
    },
    {
      name: 'accountNumber',
      render: 'FormTextField',
      required: true,
      label: 'Account Number',
    },
  ],

  RAZORPAY: [
    {
      name: 'key',
      render: 'FormTextField',
      required: true,
      label: 'Key',
    },
    {
      name: 'secret',
      render: 'FormTextField',
      required: true,
      label: 'Secret',
    },
  ],

  AIRPAY: [
    {
      name: 'merchantId',
      render: 'FormTextField',
      required: true,
      label: 'Merchant Id',
    },
    {
      name: 'secretKey',
      render: 'FormTextField',
      required: true,
      label: 'Secret Key',
    },
    {
      name: 'username',
      render: 'FormTextField',
      required: true,
      label: 'Username',
    },
    {
      name: 'password',
      render: 'FormTextField',
      required: true,
      label: 'Secret password',
    },
  ],

  BILL_DESK: [
    {
      name: 'merchantId',
      render: 'FormTextField',
      required: true,
      label: 'Merchant Id',
    },
    {
      name: 'securityId',
      render: 'FormTextField',
      required: true,
      label: 'Security Id',
    },
    {
      name: 'checksumKey',
      render: 'FormTextField',
      required: true,
      label: 'Checksum Key',
    },
  ],

  BILL_DESK_V2: [
    {
      name: 'merchantId',
      render: 'FormTextField',
      required: true,
      label: 'Merchant Id',
    },
    {
      name: 'securityId',
      render: 'FormTextField',
      required: true,
      label: 'Security Id',
    },
    {
      name: 'checksumKey',
      render: 'FormTextField',
      required: true,
      label: 'Checksum Key',
    },
  ],
  GRAY_QUEST: [
    {
      name: 'clientId',
      render: 'FormTextField',
      required: true,
      label: 'Client Id',
    },
    {
      name: 'clientSecret',
      render: 'FormTextField',
      required: true,
      label: 'Client Secret',
    },
    {
      name: 'apiKey',
      render: 'FormTextField',
      required: true,
      label: 'Api Key',
    },
    {
      name: 'configSlug',
      render: 'FormTextField',
      required: true,
      label: 'Config Slug',
    },
  ],
}
