import { FormTextField } from '@campxdev/shared'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  Alert,
  Box,
  Button,
  IconButton,
  InputAdornment,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import axios from 'config/axios'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useParams } from 'react-router-dom'

export const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: '10px',
  height: '60px',
  fontSize: '18px',
}))

export const StyledTextField = styled(FormTextField)(({ theme }) => ({
  '& .MuiInputBase-root': {
    minHeight: '60px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '18.5px 14px',
  },
}))

export default function EmailLogin() {
  const [showPassword, setShowPassword] = useState(false)
  const { handleSubmit, control } = useForm()
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const onSubmit = async (formData) => {
    try {
      const res = await axios.post('/auth/login', {
        username: formData?.username,
        password: formData?.password,
      })
      Cookies.set('campx_admin_session_key', res?.data?.cookie)
      window.location.href = '/'
    } catch (error) {
      setError(error?.response?.data?.message)
    }
  }

  return (
    <Box width={'100%'} marginTop="20px">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={'40px'}>
          <Typography variant="h6" textAlign={'center'}>
            Sign in to Super Admin account
          </Typography>
          <StyledTextField
            size="medium"
            control={control}
            name="username"
            label="Username/Email"
            required
          />
          <StyledTextField
            control={control}
            name="password"
            label="Password"
            type={showPassword ? 'text' : 'password'}
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <StyledButton type="submit" fullWidth>
            Login
          </StyledButton>
        </Stack>
      </form>
      {error && (
        <Alert severity="error" sx={{ marginTop: '20px' }}>
          {error}
        </Alert>
      )}
    </Box>
  )
}
