import axios from 'config/axios'

const USERS = '/users'

export const users = {
  async fetchAll() {
    const res = await axios.get(USERS)
    return res.data
  },

  async fetchUsers(params: {
    search?: string | number
    limit?: number
    offset?: number
    noParentMobile?: boolean
    tenantId?: string
    type: string
  }) {
    const res = await axios.get(USERS + '/all-users', {
      params,
    })
    return res.data
  },
}
