import { AxiosRequestConfig } from 'axios'
import axios from 'config/axios'

const OAUTH_URL = '/oauth-clients'

interface IOauthClient {
  name: string
  authorizedDomain: string
  tenantId?: string
  description?: string
}

export const oauth_clients = {
  async createUpdate({
    clientId,
    ...body
  }: {
    clientId: string
    body: IOauthClient
  }) {
    const config: AxiosRequestConfig = {
      method: clientId ? 'PUT' : 'POST',
      url: clientId ? `${OAUTH_URL}/${clientId}` : OAUTH_URL,
      data: {
        ...body,
      },
    }
    return axios(config).then((res) => res.data)
  },

  async fetchAll() {
    const res = await axios.get(`${OAUTH_URL}`)
    return res.data
  },
}
