import {
  ErrorBoundary,
  LayoutWrapper,
  PageNotFound,
  Permission,
  SideMenuHeader,
  withRouteWrapper,
} from '@campxdev/shared'
import AppLayout from 'layouts/AppLayout'
import { IMenuItem, dashboardMenu } from 'layouts/DashboardLayout/dashboardMenu'
import LoginPage from 'pages/LoginPage'
import { Outlet, useParams } from 'react-router-dom'
import { baseRoutes, configurationRoutes, printRoutes } from './baseRoutes'
const ConfigurationLayout = () => {
  const { id } = useParams()

  const ConfigurationMenu: Array<IMenuItem> = [
    {
      title: 'General Information ',
      path: `/config/clients/${id}`,
      permissionKey: Permission.CAN_DASHBOARD_VIEW,
    },
    {
      title: 'Payments config',
      path: `/config/payment-types/${id}`,
      permissionKey: Permission.CAN_DASHBOARD_VIEW,
    },
    {
      title: 'Frappee Config',
      path: `/config/clients/frappee-config/${id}`,
      permissionKey: Permission.CAN_DASHBOARD_VIEW,
    },
  ]
  return (
    <LayoutWrapper
      menu={ConfigurationMenu}
      sideMenuHeader={<SideMenuHeader path="/clients" title={'Back'} />}
    >
      <ErrorBoundary>
        <Outlet />
      </ErrorBoundary>
    </LayoutWrapper>
  )
}

const PrintLayout = () => {
  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  )
}

export const mainRoutes = [
  {
    path: '/',
    element: <AppLayout />,
    children: withRouteWrapper([
      {
        path: '/',
        element: (
          <LayoutWrapper menu={dashboardMenu}>
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </LayoutWrapper>
        ),
        children: baseRoutes,
      },
      {
        path: '/config',
        element: <ConfigurationLayout />,
        children: configurationRoutes,
      },
    ]),
  },
  {
    path: '/print',
    element: <PrintLayout />,
    children: withRouteWrapper(printRoutes),
  },
  {
    element: <LoginPage />,
    path: '/login',
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]
