import {
  ActionButton,
  Breadcrumbs,
  DeleteButton,
  DialogButton,
  EditButton,
  PageContent,
  PageHeader,
  Table,
  axiosErrorToast,
  useConfirm,
} from '@campxdev/shared'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import PaymentAccordien from './PaymentAccordien'
import { deleteFeeCharge, fetchFeeCharges } from './service'

function PaymentFeeCharges() {
  const { id } = useParams()
  const { isConfirmed } = useConfirm()
  const queryClient = useQueryClient()

  const { data, isLoading } = useQuery('payment-fee-charges', () =>
    fetchFeeCharges(id),
  )
  const { mutate } = useMutation(deleteFeeCharge, {
    onSuccess: (data) => {
      toast.success('FeeType Deleted Successfully')
      queryClient.invalidateQueries('payment-fee-charges')
    },
    onError: (err) => {
      console.log(err)
      axiosErrorToast(err)
    },
  })
  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },

    {
      title: 'Actions',
      dataIndex: 'actions',
      key: 'actions',
      render: (_, row) => {
        return (
          <div style={{ display: 'flex', gap: 10 }}>
            <DialogButton
              anchor={({ open }) => <EditButton onClick={open} />}
              dialogProps={{
                maxWidth: 'md',
                PaperProps: {
                  sx: {
                    minHeight: '700px',
                    height: 'auto',
                    overflowY: 'auto',
                  },
                },
              }}
              title="Edit Payment Fee Charges"
              content={({ close }) => (
                <PaymentAccordien id={id} close={close} data={row} />
              )}
            />

            <DeleteButton onClick={() => handleDelete(row.id)} />
          </div>
        )
      },
    },
  ]

  const handleDelete = async (id: any) => {
    const confirmed = await isConfirmed(
      'Are you sure you want to delete this FeeCharges?',
    )

    if (!confirmed) return
    try {
      mutate(id)
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting Fee Charges')
    }
  }

  return (
    <>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Payment Types', to: `/config/payment-types/${id}` },
              { name: 'Payment FeeCharges', to: null },
            ]}
          />
        }
        actions={
          <>
            <DialogButton
              anchor={({ open }) => (
                <ActionButton onClick={open}>Add Charges</ActionButton>
              )}
              dialogProps={{
                maxWidth: 'md',
                PaperProps: {
                  sx: {
                    minHeight: '500px',
                    height: 'auto',
                    overflowY: 'auto',
                  },
                },
              }}
              title="Add payment Fee charges"
              content={({ close }) => (
                <PaymentAccordien id={id} close={close} data={null} />
              )}
            />
          </>
        }
      />
      <PageContent>
        <Table columns={columns} dataSource={data} loading={isLoading} />
      </PageContent>
    </>
  )
}

export default PaymentFeeCharges
