import { Permission } from '@campxdev/shared'
export interface IMenuItem {
  title: string
  path: string
  icon?: any
  children?: Array<IMenuItem>
  permissionKey?: string
}

export const dashboardMenu: Array<IMenuItem> = [
  {
    title: 'Usage Analytics',
    path: '/dashboard',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'Clients',
    path: '/clients',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'Quicksight Config',
    path: '/quicksight-templates',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'Tips',
    path: '/tips',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    title: 'Faculty Report',
    path: '/download-faculty-report',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'WhatsApp Analytics',
    path: '/whatsapp-analytics',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'Mobile Apps',
    path: '/apps',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'Students',
    path: '/students',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'Faculty',
    path: '/faculty',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'Academic Timetable',
    path: '/academic-timetable',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    title: 'OAuth Clients',
    path: '/oauth-clients',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]
