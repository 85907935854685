import {
  DeleteButton,
  FormMultiSelect,
  FormSingleSelect,
  FormTextField,
} from '@campxdev/shared'
import { Button, Stack, Typography } from '@mui/material'
import { useFieldArray } from 'react-hook-form'
import { AmountTypes, BankOptions, ChargeTypes } from './service'
import { StyledFlexBox } from './styles'

function PaymentAccordionBody({
  fields,
  append,
  watch,
  keyName,
  control,
  paymentName,
  netbank = false,
  remove,
}) {
  const {
    fields: nestedFields,
    append: appendNested,
    remove: removeNested,
  } = useFieldArray({
    control: control,

    name: `${keyName}.${fields.length - 1}.range`,
  })

  return (
    <>
      <StyledFlexBox>
        {fields.length >= 1 ? (
          <Typography variant="h5">
            Add More {paymentName} FeeCharges{' '}
          </Typography>
        ) : (
          <Typography variant="h5">Add {paymentName} FeeCharges </Typography>
        )}

        <Button
          variant="text"
          size="small"
          onClick={() =>
            append({ name: '', chargeType: '', amount: '', range: '' })
          }
        >
          + Add
        </Button>
      </StyledFlexBox>
      {fields.map((field, index) => {
        return (
          <Stack
            key={field.id}
            gap={'20px'}
            sx={{
              marginY: '16px',
              backgroundColor: '#f2f2f2',
              padding: '20px',
              borderRadius: '10px',
            }}
          >
            {
              <StyledFlexBox>
                <Typography variant="h2">
                  Fee Charges Number {index + 1}
                </Typography>
                <DeleteButton onClick={() => remove(index)}></DeleteButton>
              </StyledFlexBox>
            }
            {netbank && (
              <FormMultiSelect
                control={control}
                label={'Select Bank'}
                name={`${keyName}.${index}.banks`}
                options={BankOptions}
              />
            )}
            <FormSingleSelect
              name={`${keyName}.${index}.name`}
              label="Name"
              control={control}
              options={[
                {
                  label: 'Platform Fee',
                  value: 'Platform Fee',
                },
                {
                  label: 'Payment Processing Fee',
                  value: 'Payment Processing Fee',
                },
              ]}
              required
            />

            <FormSingleSelect
              label={'Fee Charges Types'}
              control={control}
              name={`${keyName}.${index}.chargeType`}
              required
              options={ChargeTypes}
            />
            {watch(`${keyName}.${index}.chargeType`) == 'FIXED_VALUE' && (
              <>
                <FormSingleSelect
                  label={'Amount Type'}
                  name={`${keyName}.${index}.amount.amountType`}
                  control={control}
                  options={AmountTypes}
                />
                <FormTextField
                  label={'Amount '}
                  name={`${keyName}.${index}.amount.value`}
                  control={control}
                />
              </>
            )}
            {watch(`${keyName}.${index}.chargeType`) == 'RANGE_VALUES' && (
              <>
                <StyledFlexBox>
                  <Typography variant="h5">Add Range FeeCharges </Typography>
                  <Button
                    variant="text"
                    size="small"
                    onClick={() =>
                      appendNested({
                        amountType: '',
                        value: '',
                        fromAmount: '',
                        toAmount: '',
                      })
                    }
                  >
                    + range
                  </Button>
                </StyledFlexBox>
                {nestedFields.map((nestedField, nestedIndex) => (
                  <>
                    <StyledFlexBox>
                      <Typography variant="h2">
                        Fee Range {nestedIndex + 1}{' '}
                      </Typography>
                      <DeleteButton
                        onClick={() => removeNested(nestedIndex)}
                      ></DeleteButton>
                    </StyledFlexBox>

                    <FormTextField
                      label={'From Amount '}
                      control={control}
                      name={`${keyName}.${index}.range[${nestedIndex}].fromAmount`}
                      required
                    />
                    <FormTextField
                      label={'To Amount'}
                      name={`${keyName}.${index}.range[${nestedIndex}].toAmount`}
                      control={control}
                    />
                    <FormSingleSelect
                      label={'Amount Type'}
                      options={AmountTypes}
                      name={`${keyName}.${index}.range[${nestedIndex}].amount.amountType`}
                      control={control}
                      required
                    />
                    <FormTextField
                      label={'Amount '}
                      name={`${keyName}.${index}.range[${nestedIndex}].amount.value`}
                      control={control}
                      required
                    />
                  </>
                ))}
              </>
            )}
          </Stack>
        )
      })}
    </>
  )
}

export default PaymentAccordionBody
