import { ActionButton, FormTextField, axiosErrorToast } from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  Box,
  Stack,
  Typography,
} from '@mui/material'
import { useFieldArray, useForm } from 'react-hook-form'
import { useMutation, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import PaymentAccordionBody from './PaymentAccordionBody'
import { postFeeCharges, schema, updateFeeCharges } from './service'
import { StyledAccordianSummary } from './styles'

function PaymentAccordien({ id, close, data }) {
  const queryClient = useQueryClient()

  const { control, watch, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data ? data.name : null,
      upi: data
        ? data.upi
        : [{ name: '', chargeType: '', amount: '', range: '' }],
      ruPayDebitCard: data
        ? data.ruPayDebitCard
        : [{ name: '', chargeType: '', amount: '', range: '' }],
      visaDebitCard: data
        ? data.visaDebitCard
        : [{ name: '', chargeType: '', amount: '', range: '' }],
      masterCardDebitCard: data
        ? data.masterCardDebitCard
        : [{ name: '', chargeType: '', amount: '', range: '' }],
      creditCard: data
        ? data.creditCard
        : [{ name: '', chargeType: '', amount: '', range: '' }],
      wallet: data
        ? data.wallet
        : [{ name: '', chargeType: '', amount: '', range: '' }],
      prepaidCard: data
        ? data.prepaidCard
        : [{ name: '', chargeType: '', amount: '', range: '' }],
      netBanking: data
        ? data.netBanking.map((item) => {
            return {
              banks: item.banks.map((bank) => ({
                label: bank.toUpperCase(),
                value: bank,
              })),
              name: item.charges.map((value) => value.name).join(''),
              chargeType: item.charges
                .map((value) => value.chargeType)
                .join(''),
              amount: item.charges.map((value) => value.amount)[0],

              range: item.charges.map((value) => {
                return value?.range?.map((item) => item)
              })[0],
            }
          })
        : [{ name: '', chargeType: '', amount: '', range: '' }],
    },
  })
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'upi',
  })
  const {
    fields: ruPayDebitCardFileds,
    append: ruPayDebitCardAppend,
    remove: ruPayDebitCardRemove,
  } = useFieldArray({
    control,
    name: 'ruPayDebitCard',
  })

  const {
    fields: visaDebitCardFileds,
    append: visaDebitCardAppend,
    remove: visaDebitCardRemove,
  } = useFieldArray({
    control,
    name: 'visaDebitCard',
  })
  const {
    fields: masterCardDebitCardFileds,
    append: masterCardDebitCardAppend,
    remove: masterCardDebitCardRemove,
  } = useFieldArray({
    control,
    name: 'masterCardDebitCard',
  })
  const {
    fields: creditCardFileds,
    append: creditCardAppend,
    remove: creditCardRemove,
  } = useFieldArray({
    control,
    name: 'creditCard',
  })
  const {
    fields: walletFileds,
    append: walletAppend,
    remove: walletRemove,
  } = useFieldArray({
    control,
    name: 'wallet',
  })
  const {
    fields: prepaidCardFileds,
    append: prepaidCardAppend,
    remove: prepaidCardRemove,
  } = useFieldArray({
    control,
    name: 'prepaidCard',
  })
  const {
    fields: netBankingFileds,
    append: netBankingAppend,
    remove: netBankingRemove,
  } = useFieldArray({
    control,
    name: 'netBanking',
  })
  const { mutate, isLoading } = useMutation(postFeeCharges, {
    onSuccess: (data) => {
      close()
      toast.success('Posted Successfully')
      queryClient.invalidateQueries('payment-fee-charges')
    },
    onError: (err) => {
      console.log(err)
      axiosErrorToast(err)
    },
  })
  const { mutate: updateCharges, isLoading: update } = useMutation(
    updateFeeCharges,
    {
      onSuccess: (data) => {
        close()
        toast.success('updated Successfully')
        queryClient.invalidateQueries('payment-fee-charges')
      },
      onError: (err) => {
        console.log(err)
        axiosErrorToast(err)
      },
    },
  )
  function removeEmptyObjectsFromArray(arr) {
    return arr.filter((obj) => Object.values(obj).some((value) => value !== ''))
  }
  const onsubmit = (formdata) => {
    for (let key in formdata) {
      if (Array.isArray(formdata[key])) {
        formdata[key] = removeEmptyObjectsFromArray(formdata[key])
      }
    }

    const banking = formdata?.netBanking?.map((item) => {
      const banksArray = item?.banks?.map((bank) => bank.value)
      return {
        charges: [
          {
            amount: item?.amount,
            name: item?.name,
            chargeType: item?.chargeType,
            range: item?.range,
          },
        ],
        banks: banksArray,
      }
    })

    const postData = {
      tenantId: id,
      name: formdata.name,
      data: { ...formdata, netBanking: banking },
    }
    data ? updateCharges({ id: data.id, postBody: postData }) : mutate(postData)
  }
  const onerror = (err) => {
    console.log(err, 'this is err')

    axiosErrorToast(err)
  }
  return (
    <form onSubmit={handleSubmit(onsubmit, onerror)}>
      <FormTextField
        control={control}
        name="name"
        label={'Enter Name'}
        sx={{ marginBottom: '20px' }}
        required
      />
      <CustomAccordion name={'UPI'}>
        <PaymentAccordionBody
          append={append}
          control={control}
          fields={fields}
          keyName={'upi'}
          paymentName={'UPI'}
          watch={watch}
          remove={remove}
        />
      </CustomAccordion>
      {/* <CustomAccordion name={'RuPay Debit Card'}>
        <PaymentAccordionBody
          append={ruPayDebitCardAppend}
          control={control}
          fields={ruPayDebitCardFileds}
          keyName={'ruPayDebitCard'}
          paymentName={'RuPayDebitCard'}
          watch={watch}
          remove={ruPayDebitCardRemove}
        />
      </CustomAccordion> */}
      <CustomAccordion name={'Visa/Master Debit Card'}>
        <PaymentAccordionBody
          append={visaDebitCardAppend}
          control={control}
          fields={visaDebitCardFileds}
          keyName={'visaDebitCard'}
          paymentName={'VisaDebitCard'}
          watch={watch}
          remove={visaDebitCardRemove}
        />
      </CustomAccordion>
      {/* <CustomAccordion name={'Master Debit Card'}>
        <PaymentAccordionBody
          append={masterCardDebitCardAppend}
          control={control}
          fields={masterCardDebitCardFileds}
          keyName={'masterCardDebitCard'}
          paymentName={'MasterCardDebitCard'}
          watch={watch}
          remove={masterCardDebitCardRemove}
        />
      </CustomAccordion> */}
      <CustomAccordion name={'Credit Card'}>
        <PaymentAccordionBody
          append={creditCardAppend}
          control={control}
          fields={creditCardFileds}
          keyName={'creditCard'}
          paymentName={'CreditCard'}
          watch={watch}
          remove={creditCardRemove}
        />
      </CustomAccordion>
      {/* <CustomAccordion name={'Wallet'}>
        <PaymentAccordionBody
          append={walletAppend}
          control={control}
          fields={walletFileds}
          keyName={'wallet'}
          paymentName={'Wallet'}
          watch={watch}
          remove={walletRemove}
        />
      </CustomAccordion> */}
      <CustomAccordion name={'NetBanking'}>
        <PaymentAccordionBody
          append={netBankingAppend}
          control={control}
          fields={netBankingFileds}
          keyName={'netBanking'}
          paymentName={'NetBanking'}
          watch={watch}
          netbank={true}
          remove={netBankingRemove}
        />
      </CustomAccordion>
      {/* <CustomAccordion name={'Prepaid Card'}>
        <PaymentAccordionBody
          append={prepaidCardAppend}
          control={control}
          fields={prepaidCardFileds}
          keyName={'prepaidCard'}
          paymentName={'PrepaidCard'}
          watch={watch}
          remove={prepaidCardRemove}
        />
      </CustomAccordion> */}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: '20px',
        }}
      >
        <Stack direction={'row'} gap={'20px'}>
          <ActionButton
            size="small"
            fullWidth
            variant="outlined"
            onClick={() => close()}
            sx={{ padding: '10px', minWidth: '131px' }}
          >
            Close
          </ActionButton>
          <ActionButton
            type="submit"
            size="small"
            fullWidth
            sx={{ padding: '10px', minWidth: '131px' }}
            loading={update || isLoading}
          >
            Submit
          </ActionButton>
        </Stack>
      </Box>
    </form>
  )
}

export default PaymentAccordien

const CustomAccordion = ({ children, name }) => {
  return (
    <Box sx={{ border: '1px solid #EEEEEE', marginBottom: '10px' }}>
      <Accordion
        elevation={0}
        disableGutters={true}
        TransitionProps={{ unmountOnExit: true }}
      >
        <StyledAccordianSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h5">{name}</Typography>
        </StyledAccordianSummary>
        <AccordionDetails>{children}</AccordionDetails>
      </Accordion>
    </Box>
  )
}
