import React from 'react'
import { PageHeader, PageContent } from '@campxdev/shared'
import QuicksightUpdateComponent from './QuicksightUpdateComponent'
import { useQuery } from 'react-query'
import { SERVICES } from 'services'

const QuicksightTemplates = () => {
  const { data: templates, isLoading } = useQuery(
    'dashboardManager',
    SERVICES.dashboardManager.getAll,
  )
  return (
    <>
      <PageHeader title={'Quicksight Configuration'} />
      <PageContent>
        {templates?.map((template, index) => (
          <QuicksightUpdateComponent key={index} template={template} />
        ))}
      </PageContent>
    </>
  )
}

export default QuicksightTemplates
