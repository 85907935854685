import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

export interface Logs {
  createdAt: string
  createdById: string
  id: string
  status: string
  tenantId: string
  updatedAt: string
}

export interface FrappeDataSyncLogs {
  logs: Logs[]
}

export const StyledBanner = styled(Box)(({ theme }) => ({
  height: '150px',
  background: 'linear-gradient(to right, #1E28F5, #149ECD)',
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  color: 'white',
  position: 'relative',
  '& .MuiTypography-root': {
    color: 'white',
    fontSize: '20px',
  },
}))

export const StyledStatus = styled(Typography)<{
  statusType: 'In Progress' | 'Failed' | 'Completed'
}>(({ statusType }) => ({
  fontWeight: '700',
  color:
    statusType === 'In Progress'
      ? '#EE9C38'
      : statusType === 'Failed'
      ? '#F2353C'
      : statusType === 'Completed'
      ? '#1E19F5'
      : '',
}))

export const generationStatus = {
  IN_PROGRESS: 'In Progress',
  FAILED: 'Failed',
  COMPLETED: 'Completed',
}

export const StyledStatusComponent = ({ status }) => {
  return (
    <StyledStatus statusType={generationStatus[status]}>
      {generationStatus[status]}
    </StyledStatus>
  )
}
