import { Spinner } from '@campxdev/shared'
import { Box, Button, Typography } from '@mui/material'
import axios from 'config/axios'
import { useQuery } from 'react-query'
import * as XLSX from 'xlsx'

function FacultyReport() {
  const { data, isLoading } = useQuery('faculty-report', () =>
    axios.get('/users/all-faculty').then((res) => res.data.result),
  )

  function excelReport(data) {
    const filename = 'FacultyReport.xlsx'
    const wb: XLSX.WorkBook = XLSX.utils.book_new()

    let heading = ['Name', 'Phone', 'AllowBroadcast', 'AllowSMS', 'College']

    var finalReport = []

    for (let i = 0; i < data.length; i++) {
      data[i].map((item) => {
        finalReport.push(item)
      })
    }

    const reportData = finalReport?.map((obj) => [
      obj.fullName,
      obj.mobile,
      obj.allowBroadcast,
      obj.allowSMS,
      obj.college,
    ])

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet([])
    XLSX.utils.sheet_add_aoa(ws, [heading], { origin: 'A1' })
    XLSX.utils.sheet_add_aoa(ws, reportData, { origin: 'A2' })

    XLSX.utils.book_append_sheet(wb, ws, 'Faculty Report')
    XLSX.writeFile(wb, filename)
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        alignItems: 'center',
      }}
    >
      <Typography variant="h6" sx={{ mt: 4 }}>
        Faculty Data Downloaded
      </Typography>
      <Button variant="outlined" onClick={() => excelReport(data)}>
        Download
      </Button>
    </Box>
  )
}

export default FacultyReport

// const Test = () => {
//   const { isLoading, mutate } = useMutation(() =>
//     axios.get('/users/temporary-api'),
//   )

//   if (isLoading) return <Spinner />
//   return (
//     <>
//       <Button
//         onClick={() => {
//           mutate()
//         }}
//       >
//         Click
//       </Button>
//     </>
//   )
// }
