import { Breadcrumbs, PageContent, Spinner } from '@campxdev/shared'
import { Box, Typography, styled } from '@mui/material'
import LogoutButton from 'components/SuperAdminLogoutButton'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { SERVICES } from 'services'
import TenantCreationForm from './TenantCreationForm'

const StyledBanner = styled(Box)(({ theme }) => ({
  height: '150px',
  background: `linear-gradient(to right,#1E28F5, #149ECD)`,
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  color: 'white',
  position: 'relative',
  '& .MuiTypography-root': {
    color: 'white',
    fontSize: '20px',
  },
}))

export default function TenantCreation() {
  const { id } = useParams()

  const { data, isLoading } = useQuery(
    ['clients', id],
    () => SERVICES.tenant.fetchClientById(id),
    {
      enabled: !!id,
    },
  )

  if (isLoading) return <Spinner />

  return (
    <>
      <StyledBanner>
        <Typography variant="h1">CampX Tenant Registration</Typography>
        <LogoutButton />
      </StyledBanner>
      <PageContent>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Breadcrumbs
            links={[
              { name: 'Clients', to: '/clients' },
              {
                name: !!id ? `${data?.name}` : 'New',
                to: null,
              },
            ]}
          />
        </Box>
        <Box maxWidth={'900px'} margin="20px auto">
          <TenantCreationForm data={data} />
        </Box>
      </PageContent>
      <LogoutButton />
    </>
  )
}
