import {
  DeleteButton,
  DrawerButton,
  EditButton,
  Image,
  useConfirm,
} from '@campxdev/shared'
import { Box, Stack, styled, Typography } from '@mui/material'
import axios from 'config/axios'
import { useQueryClient } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import CategoryTipsForm from './CategoryTipForm'

const StyledCardContainer = styled(Stack)(({ theme }) => ({
  padding: '20px',
  border: theme.borders.grayLight,
  borderRadius: '10px',
  gap: '20px',
}))

export default function TipsCard({ data }) {
  const params = useParams()
  const { isConfirmed } = useConfirm()
  const queryClient = useQueryClient()

  const onDelete = async (id) => {
   
    const confirmed = await isConfirmed(
      'Are you sure you want to delete this Tip?',
    )
    if (!confirmed) return

    try {
      await axios.delete(`/tips-categories/${params.id}/tips/${id}`)
      toast.success('Tip Deleted Successfully')
      queryClient.invalidateQueries('categoriesData')
    } catch (error) {
      toast.error(error.response.data.message ?? 'Error saving Category')
    }
  }

  return (
    <StyledCardContainer>
      <Box sx={{ display: 'flex', justifyContent: 'end', gap: '20px' }}>
        <DrawerButton
          anchor={({ open }) => <EditButton onClick={open} />}
          key="1"
          content={({ close }) => (
            <CategoryTipsForm data={data} hideDialog={close} />
          )}
          title="Edit Tips"
        />
        <DeleteButton
          onClick={() => {
            onDelete(data?._id)
          }}
        />
      </Box>
      <Image alt={data?.title} height={300} width="100%" src={data?.imageKey} />
      <Box>
        <Typography variant="h6">{data?.title}</Typography>
        <Typography>{data?.description}</Typography>
      </Box>
    </StyledCardContainer>
  )
}
