import axios from 'config/axios'
import * as yup from 'yup'

export const BankOptions = [
  {
    label: 'SBI',
    value: 'sbi',
  },
  {
    label: 'HDFC',
    value: 'hdfc',
  },
  {
    label: 'ICICI',
    value: 'icici',
  },
  {
    label: 'Axis ',
    value: 'axis',
  },
  {
    label: 'Other',
    value: 'other',
  },
]
export const ChargeTypes = [
  { label: 'Fixed ', value: 'FIXED_VALUE' },
  { label: 'Range ', value: 'RANGE_VALUES' },
]
export const AmountTypes = [
  { label: 'Percentage', value: 'PERCENTAGE' },
  { label: 'Fixed', value: 'FIXED' },
]

export const fetchFeeCharges = (id) => {
  return axios
    .get('/tenant-payment-fee-charges', {
      params: { tenantId: id },
    })
    .then((res) => res.data)
}

export const postFeeCharges = (postBody) => {
  return axios
    .post('/tenant-payment-fee-charges', postBody)
    .then((res) => res.data)
}

export const updateFeeCharges = ({ id, postBody }) => {
  return axios.put(`/tenant-payment-fee-charges/${id}`, postBody)
}

export const deleteFeeCharge = (id) => {
  return axios.delete(`/tenant-payment-fee-charges/${id}`)
}

export const schema = yup.object().shape({
  name: yup.string().required('Name is required').nullable(),
})
