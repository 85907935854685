import { styled, Box } from '@mui/material'

export const StyledMain = styled(Box)(({ theme }) => ({
  minHeight: '100vh',
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  '@media(max-width: 1024px)': {
    display: 'flex',
    width: '100%',
  },
}))

export const StyledBanner = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: 'linear-gradient(135deg, #1E19F5 0%, #0096D1 100%)',

  '@media(max-width: 1024px)': {
    display: 'none',
  },
}))
export const StyledFormSection = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  maxWidth: '460px',
  margin: 'auto',
  padding: '0 16px',
}))

export const StyledBannerImage = styled(Box)(() => ({
  height: 'calc(100vh - 90px - 56px)',
  width: '80%',
  margin: '0 auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  '& img': {
    width: '100%',
    height: 'auto',
    objectfit: 'contain',
  },
}))
export const StyledBannerFooter = styled(Box)(() => ({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
  '& .logo': {
    height: '90px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: '6px',
    '& img': {
      display: 'block',
      width: '100px',
      height: 'auto',
    },
  },
  '& .copyright': {
    height: '56px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}))
