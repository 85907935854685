import { AxiosRequestConfig } from 'axios'
import axios from 'config/axios'
import { FrappeDataSyncLogs } from 'pages/SuperAdmin/FrapeeConfig/service'

const TENANT_URL = '/tenants'
type CreateTenantDto = {
  _id?: string
  name?: string
  subDomain?: string
  password?: string
  env: {
    razorpayKey: string
    razorpaySecret: string
  }
  smtpConfig: {
    host: string
    port: string
    username: string
    from: string
    password: string
    secure: string
  }
}

export const tenant = {
  async fetchAll() {
    const res = await axios.get(TENANT_URL)
    return res.data
  },

  async twoFactorAuthentication(params: {
    tenantId: string
    twoFactorAuthentication: boolean
  }) {
    const EndPoint = `${TENANT_URL}/${params.tenantId}/${
      params.twoFactorAuthentication ? 'enable' : 'disable'
    }-two-factor-auth`
    return axios
      .put(EndPoint)
      .then((res) => res.data)
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error)
      })
  },

  async fetchAllDetailed() {
    const res = await axios.get(TENANT_URL + '/detailed')
    return res.data
  },
  async fetchAllTipsCategories() {
    const res = await axios.get('/tips-categories')
    return res.data
  },
  async fetchClientById(clientId: string) {
    const res = await axios.get(TENANT_URL + `/${clientId}`)
    return res.data
  },
  async postTenant(body: CreateTenantDto) {
    const config: AxiosRequestConfig = {
      method: body?._id ? 'PUT' : 'POST',
      data: body,
      url: body?._id ? `${TENANT_URL}/${body?._id}` : TENANT_URL,
    }

    return axios(config)
  },
  async updateTenant(body: any) {
    const config: AxiosRequestConfig = {
      method: 'PUT',
      data: body,
      url: `${TENANT_URL}/${body?._id}`,
    }

    return axios(config)
  },

  //Frappee config
  async createOrUpdateFrappeConfig({
    tenantId,
    body,
  }: {
    tenantId: string
    body: any
  }) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      data: body,
      url: `${TENANT_URL}/${tenantId}/frappe-config`,
    }

    return axios(config)
  },

  async enableFrappeSync(tenantId: string) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${TENANT_URL}/${tenantId}/enable-frappe-sync`,
    }

    return axios(config)
  },

  async frappeDataSync(tenantId: string) {
    const config: AxiosRequestConfig = {
      method: 'POST',
      url: `${TENANT_URL}/${tenantId}/frappe-data-sync`,
    }

    return axios(config)
  },

  async getFrappeDataSyncLogs(tenantId: string): Promise<FrappeDataSyncLogs> {
    const res = await axios.get(
      `${TENANT_URL}/${tenantId}/frappe-data-sync-logs`,
    )

    return res.data
  },
}
