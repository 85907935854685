import {
  ActionButton,
  FormAutoCompleteSearch,
  FormSingleSelect,
} from '@campxdev/shared'
import { Box, IconButton, Stack, Typography, styled } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { SERVICES } from 'services'
function AppFilters({ setFilters }) {
  const { handleSubmit, control } = useForm({})
  const { data, isLoading } = useQuery(
    'fetch-all-tenants',
    SERVICES.tenant.fetchAll,
  )
  const onSubmit = (formdata) => {
    setFilters((s) => {
      s.appName = formdata?.appName
      s.tenantId = formdata?.tenantId?.value
    })
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack
        gap={2.5}
        direction={'row'}
        sx={{ alignItems: 'flex-end', maxWidth: '900px', my: '20px' }}
      >
        <Box sx={{ minWidth: '400px' }}>
          <Typography variant="subtitle2" sx={{ fontSize: '15px' }}>
            College Name
          </Typography>
          <FormAutoCompleteSearch
            name="tenantId"
            label={''}
            sx={{ height: '50px', padding: '10px' }}
            control={control}
            options={data?.result?.map((tenant) => ({
              label: tenant.clientName,
              value: tenant._id,
            }))}
          />
        </Box>
        <FormSingleSelect
          name="appName"
          label={'App Name'}
          control={control}
          options={[
            {
              label: 'Student App',
              value: 'campx-student-app',
            },
            {
              label: 'Faculty App',
              value: 'campx-faculty-app',
            },
          ]}
        />

        <ActionButton type="submit">Submit</ActionButton>
      </Stack>
    </form>
  )
}

export default AppFilters
export const StyledClearButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  border: theme.borders.grayLight,
}))
