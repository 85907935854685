import { ActionButton, axiosErrorToast, RenderForm } from '@campxdev/shared'
import {
  Field,
  generateYupSchema,
} from '@campxdev/shared/src/components/Form/RenderForm'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'

const FormSection = ({ title, children, noPadding = false }) => {
  return (
    <Box sx={{ position: 'relative', marginBottom: '20px', margin: '0 10px' }}>
      <Typography variant="h6">{title}</Typography>
      <Box sx={{ padding: noPadding ? '16px 0' : '16px' }}>{children}</Box>
    </Box>
  )
}

const envFields: Field[] = [
  {
    name: 'clientName',
    render: 'FormTextField',
    required: true,
    label: 'Client Name',
  },

  {
    name: 'studentAndroidApp',
    render: 'FormTextField',
    required: true,
    label: 'Student Android App ',
  },
  {
    name: 'studentIOSApp',
    render: 'FormTextField',
    required: true,
    label: 'Student IOS App ',
  },
  {
    name: 'facultyAndroidApp',
    render: 'FormTextField',
    required: true,
    label: 'Faculty Android App ',
  },
  {
    name: 'facultyIOSApp',
    render: 'FormTextField',
    required: true,
    label: 'Faculty IOS App ',
  },
]

const smtpConfigFields: Field[] = [
  {
    name: 'host',
    render: 'FormTextField',
    required: true,
    label: 'Host',
  },
  {
    name: 'port',
    render: 'FormTextField',
    required: true,
    label: 'Port',
  },
  {
    name: 'username',
    render: 'FormTextField',
    required: true,
    label: 'Username',
  },
  {
    name: 'from',
    render: 'FormTextField',
    required: true,
    label: 'From email',
  },
  {
    name: 'password',
    render: 'FormTextField',
    required: true,
    label: 'Password',
    elementProps: {
      sx: {
        marginBottom: '20px',
      },
    },
  },
]

const textLocalFields: Field[] = [
  {
    name: 'apiKey',
    render: 'FormTextField',
    required: true,
    label: 'API Key',
  },
  {
    name: 'sender',
    render: 'FormTextField',
    required: true,
    label: 'Sender',
  },
]

const paytmConfigFields: Field[] = [
  {
    name: 'merchantId',
    render: 'FormTextField',
    required: true,
    label: 'Merchant Id',
  },
  {
    name: 'merchantKey',
    render: 'FormTextField',
    required: true,
    label: 'Merchant Key',
  },
  {
    name: 'website',
    render: 'FormTextField',
    required: true,
    label: 'Website',
  },
  {
    name: 'industryType',
    render: 'FormTextField',
    required: true,
    label: 'Industry Type',
  },
  {
    name: 'callbackUrl',
    render: 'FormTextField',
    required: false,
    label: 'Callback Url',
  },
  {
    name: 'channelId',
    render: 'FormTextField',
    required: true,
    label: 'Channel Id',
  },
  {
    name: 'transactionUrl',
    render: 'FormTextField',
    required: true,
    label: 'Transaction Url',
  },
]

const fields = (canEdit): Field[] => [
  {
    name: 'name',
    render: 'FormTextField',
    required: canEdit ? false : true,
    label: 'College Name',
    disabled: canEdit,
  },
  {
    name: 'frappeCustomerName',
    render: 'FormTextField',
    required: canEdit ? false : true,
    label: 'Frappe Customer Name',
    disabled: canEdit,
  },
  {
    name: 'subDomain',
    render: 'FormTextField',
    required: canEdit ? false : true,
    label: 'Sub Domain',
    disabled: canEdit,
  },
  {
    label: 'Password',
    name: 'password',
    render: 'FormTextField',
    required: canEdit ? false : true,
    disabled: canEdit,
    elementProps: {
      type: 'password',
    },
  },
  {
    name: 'institutionType',
    render: 'FormSingleSelect',
    required: canEdit ? false : true,
    label: 'Institution Type',
    disabled: canEdit,
  },
]

export default function TenantCreationForm({ data }) {
  const navigate = useNavigate()
  const canEdit = Boolean(data)

  const { control, handleSubmit, setValue } = useForm({
    resolver: yupResolver(
      generateYupSchema({
        fieldGroups: [fields(canEdit)],
      }),
    ),
  })

  useEffect(() => {
    if (data) {
      Object.entries(data).forEach((item: any[]) => {
        setValue(item[0], item[1])
      })
    }
  }, [data])

  const { mutate, isLoading: posting } = useMutation(
    SERVICES.tenant.postTenant,
    {
      onSuccess(data) {
        toast.success('Tenant Created/Updated Successfully')
        navigate('/clients')
      },
      onError(error) {
        axiosErrorToast(error)
      },
    },
  )

  const onSubmit = async (formData) => {
    let postBody = {
      ...formData,
      env: {},
    }

    mutate({
      ...postBody,
    })
  }

  const onError = (error) => {
    // eslint-disable-next-line no-console
    console.log(error)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit, onError)}>
        <Stack gap={2.5}>
          <FormSection title="General Information">
            <RenderForm
              fields={fields(canEdit)}
              cols={1}
              control={control}
              dropdowns={{
                institutionType: [
                  { label: 'Individual', value: 'INDIVIDUAL' },
                  { label: 'Master Child', value: 'MASTER_CHILD' },
                ],
              }}
            />
          </FormSection>
        </Stack>
        <Box display="flex" gap="20px" justifyContent="center" marginTop="20px">
          <ActionButton type="submit" loading={posting} disabled={posting}>
            Submit
          </ActionButton>
        </Box>
      </form>
      {posting && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)',
            display: 'grid',
            placeItems: 'center',
          }}
        >
          <Paper sx={{ padding: '40px 60px' }}>
            <Typography variant="h6">
              Creating tenant, do not refresh page
            </Typography>
          </Paper>
        </Box>
      )}
    </>
  )
}
