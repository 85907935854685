import { getUrlParams, ReportHeader, Spinner } from '@campxdev/shared'
import { Box, styled } from '@mui/material'
import { useQuery } from 'react-query'
import { SERVICES } from 'services'
import { useImmer } from 'use-immer'

export const StyledTableContainer = styled(Box)({
  background: 'white',
  borderRadius: '16px',
  marginTop: '10px',
  '& table thead tr': {
    backgroundColor: '#efefef',
  },
  '& table tr th': {
    border: '1px solid #bebebe',
    padding: '2px 8px',
    textAlign: 'center',
  },

  '& table td': {
    border: '1px solid #bebebe',
    padding: '5px 10px',
    textAlign: 'center',
    fontWeight: 900,
  },

  '& table': {
    width: ' 100%',
    borderCollapse: 'collapse',
  },
})

export const StyledContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '1cm 0.5cm 0 0.5cm',
  '@media print': {
    marginLeft: '0.5cm',
    pageBreakAfter: 'always',
    marginRight: '0.5cm',
  },
})

export default function PrintAcademicTimetable() {
  const [state, setState] = useImmer({
    data: [],
    headers: [],
    fromDate: null,
    toDate: null,
    examinationType: '',
  })
  const params = getUrlParams()
  const { isLoading } = useQuery(
    [
      'academic-timetable',
      params.fromDate,
      params.toDate,
      params.examinationType,
    ],
    () =>
      SERVICES.timetable.fetchAll({
        fromDate: params.fromDate,
        toDate: params.toDate,
        ...(params.examinationType && {
          examinationType: params.examinationType,
        }),
      }),
    {
      onSuccess: (res) => {
        setState((s) => {
          s.data = res?.data || []
          s.headers = res?.headers || []
        })
      },
    },
  )

  if (isLoading) return <Spinner />

  return (
    <StyledContainer>
      <ReportHeader
        phone={{ hide: true }}
        recognitionDetails={{ hide: true }}
        typographyList={[{ text: 'Academic Timetable Report', variant: 'h6' }]}
      />
      <StyledTableContainer>
        <table>
          <thead>
            <tr>
              {state.headers.map((header) => (
                <th key={header}>{header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {state.data.map((item, index) => (
              <tr key={index}>
                {state.headers.map((header) => (
                  <td key={header}>{item[header]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </StyledTableContainer>
    </StyledContainer>
  )
}
