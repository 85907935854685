import {
  Breadcrumbs,
  DialogButton,
  PageContent,
  PageHeader,
  Spinner,
  Table,
  ViewButton,
} from '@campxdev/shared'
import { Box, Stack, Typography } from '@mui/material'
import { useQuery } from 'react-query'
import { SERVICES } from 'services'
import { FailedMessages } from './FailedMessages'

function WhatsappAnalytics() {
  const { data, isLoading } = useQuery('whatsapp-analytics', () =>
    SERVICES.analytics.fetchWhatsAppAnalytics(),
  )
  if (isLoading) return <Spinner />
  return (
    <>
      <PageHeader
        title={
          <Breadcrumbs
            links={[{ name: 'Whatsapp Usage Analytics', to: null }]}
          />
        }
      />
      <PageContent>
        <Box sx={{ marginBottom: '26px' }}>
          <Typography variant="h6">Total Report</Typography>
          <Table columns={columns} dataSource={data} />
        </Box>
        <Box>
          <Typography variant="h6">Today's Report</Typography>
          <Table columns={todayColumns} dataSource={data} />
        </Box>
      </PageContent>
    </>
  )
}

const columns = [
  {
    title: 'Tenant',
    dataIndex: '_id.clientName',
    key: 'college',
  },
  {
    title: 'Total Count',
    dataIndex: 'TotalCount',
    key: 'total',
  },

  {
    title: 'Total Success Messages Count',
    dataIndex: 'TotalDeliveredCount',
    key: 'success',
  },

  {
    title: 'Total Failed Messages Count',
    dataIndex: 'TotalFailedCount',
    key: 'failed',
  },
  {
    title: 'Failed Numbers',
    key: 'failedNumbers',
    dataIndex: 'failedNumbers',
    render: (celldata, row) => (
      <>
        <Stack gap={1.5} direction={'row'}>
          <DialogButton
            anchor={({ open }) => <ViewButton onClick={open} />}
            dialogProps={{
              maxWidth: 'md',
              disableEscapeKeyDown: true,
              PaperProps: {
                sx: {
                  overflowY: 'auto',
                  maxHeight: 'calc(100vh - 300px)',
                },
              },
            }}
            content={({ close }) => {
              return (
                <Box sx={{ margin: '16px' }}>
                  <FailedMessages clientName={row._id.tenantId} />
                </Box>
              )
            }}
            title="Failed Numbers"
          />
        </Stack>
      </>
    ),
  },
]

const todayColumns = [
  {
    title: 'Tenant',
    dataIndex: '_id.clientName',
    key: 'college',
  },
  {
    title: 'Today Count',
    dataIndex: 'TodayCount',
    key: 'total',
  },

  {
    title: 'Today Success Messages Count',
    dataIndex: 'TodayDeliveredCount',
    key: 'success',
  },

  {
    title: 'Today Failed Messages Count',
    dataIndex: 'TodayFailedCount',
    key: 'failed',
  },
  {
    title: 'Failed Numbers',
    key: 'failedNumbers',
    dataIndex: 'failedNumbers',
    render: (celldata, row) => (
      <>
        <Stack gap={1.5} direction={'row'}>
          <DialogButton
            anchor={({ open }) => <ViewButton onClick={open} />}
            dialogProps={{
              maxWidth: 'md',
              disableEscapeKeyDown: true,
              PaperProps: {
                sx: {
                  overflowY: 'auto',
                  maxHeight: 'calc(100vh - 300px)',
                },
              },
            }}
            content={({ close }) => {
              return (
                <Box sx={{ margin: '16px' }}>
                  <FailedMessages clientName={row._id.tenantId} today={true} />
                </Box>
              )
            }}
            title="Failed Numbers"
          />
        </Stack>
      </>
    ),
  },
]

export default WhatsappAnalytics
