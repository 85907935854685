import {
  ActionButton,
  DrawerButton,
  EditButton,
  FormSingleSelect,
  FormTextField,
  PageContent,
  PageHeader,
  Spinner,
  Table,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack } from '@mui/material'
import { useForm } from 'react-hook-form'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import { oauth_clients } from 'services/oauth-clients'
import { tenant } from 'services/tenant'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  authorizedDomain: yup.string().required('Authorized Domain is required'),
})

const columns = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  {
    title: 'Authorized Domain',
    dataIndex: 'authorizedDomain',
    key: 'authorizedDomain',
  },
  {
    title: 'Tenant ID',
    dataIndex: 'tenantId',
    key: 'tenantId',
    render: (data) => data || '-',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    render: (data) => data || '-',
  },
  {
    title: 'Actions',
    key: 'actions',
    dataIndex: 'actions',
    render: (_, row) => (
      <Stack gap={1.5} direction={'row'}>
        <DrawerButton
          title="Edit OAuth Client"
          anchor={({ open }) => <EditButton onClick={open} />}
          content={({ close }) => (
            <OAuthClientsForm data={row} hideDialog={close} />
          )}
        />
      </Stack>
    ),
  },
]

const OAuthClients = () => {
  const { data, isLoading } = useQuery('oauth-clients', oauth_clients.fetchAll)

  if (isLoading) return <Spinner />

  return (
    <>
      <PageHeader
        title="OAuth Clients"
        actions={[
          <DrawerButton
            anchor={({ open }) => (
              <ActionButton onClick={open}>Add New Client</ActionButton>
            )}
            key="1"
            content={({ close }) => (
              <OAuthClientsForm hideDialog={close} data={null} />
            )}
            title="Add New"
          />,
        ]}
      />
      <PageContent>
        <Table columns={columns} dataSource={data?.clients || []} />
      </PageContent>
    </>
  )
}

export default OAuthClients

const OAuthClientsForm = ({ data, hideDialog }) => {
  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: data?.name || '',
      authorizedDomain: data?.authorizedDomain || '',
      tenantId: data?.tenantId || '',
      description: data?.description || '',
    },
  })
  const queryClient = useQueryClient()

  const { data: tenants, isLoading: tenantsLoading } = useQuery('tenants', () =>
    tenant.fetchAll(),
  )

  const { mutate, isLoading } = useMutation(oauth_clients.createUpdate, {
    onSuccess: () => {
      queryClient.invalidateQueries('oauth-clients')
      toast.success(`OAuth Client ${data ? 'updated' : 'created'} successfully`)
      hideDialog()
    },
    onError: (error: any) => {
      toast.error(error.response?.data?.message || 'Error creating Client')
    },
  })

  const onSubmit = (formData) => {
    mutate({
      clientId: data?.clientId,
      ...formData,
    })
  }

  if (tenantsLoading) return <Spinner />

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack gap={2}>
        <FormTextField name="name" label="Name" control={control} required />
        <FormTextField
          name="authorizedDomain"
          label="Authorized Domain"
          control={control}
          required
        />
        <FormSingleSelect
          name="tenantId"
          label="Tenant ID"
          control={control}
          options={tenants?.result?.map((tenant) => ({
            value: tenant._id,
            label: tenant.name,
          }))}
        />
        <FormTextField
          name="description"
          label="Description"
          minRows={3}
          multiline
          control={control}
        />
      </Stack>
      <Stack direction="row" gap={2} mt={2}>
        <ActionButton type="submit" fullWidth loading={isLoading}>
          {`${data ? 'Update' : 'Add'} Client`}
        </ActionButton>
        <ActionButton variant="outlined" fullWidth onClick={hideDialog}>
          Cancel
        </ActionButton>
      </Stack>
    </form>
  )
}
