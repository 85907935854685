import axios from 'config/axios'
import { activityLogTypes } from 'constants/activityLogTypes'
import * as XLSX from 'xlsx'

export const getStatusCondition = (thisMonth: number, lastMonth: number) => {
  const var1 = thisMonth ?? 0
  const var2 = lastMonth ?? 0
  return var1 > var2 ? 'increase' : var1 === var2 ? 'equal' : 'decrease'
}

const ANALYTICS = '/analytics'

const logTypes = [
  activityLogTypes.ATTENDANCE.add,
  activityLogTypes.POST.announcement,
  activityLogTypes.POST.blog,
  activityLogTypes.POST.event,
  activityLogTypes.TASK.cancelled,
  activityLogTypes.TASK.completed,
  activityLogTypes.TASK.created,
  activityLogTypes.TASK['on-hold'],
  activityLogTypes.TASK.ongoing,
]

export const analytics = {
  async fetchAll() {
    const res = await axios.get(ANALYTICS, {
      params: {
        logType: [...logTypes],
      },
    })
    return res.data
  },

  async fetchMonthlyActiveUsers(params: { tenantId?: string }) {
    const totalActiveUsers = await axios.get(
      `${ANALYTICS}/monthly-active-users`,
      { params: { type: null, tenantId: params.tenantId } },
    )

    const activeStaffUsers = await axios.get(
      `${ANALYTICS}/monthly-active-users`,
      { params: { type: 'staff_user', tenantId: params.tenantId } },
    )

    const activeStudentUsers = await axios.get(
      `${ANALYTICS}/monthly-active-users`,
      { params: { type: 'student', tenantId: params.tenantId } },
    )

    return {
      totalActive: totalActiveUsers.data,
      activeStaffUsers: activeStaffUsers.data,
      activeStudentUsers: activeStudentUsers.data,
    }
  },

  async fetchTotalUsersData(params: { tenantId?: string }) {
    const res = await axios.get(`${ANALYTICS}/total-users-statistics`, {
      params,
    })
    return res.data
  },

  async fetchPaymentsStatistics(params: { tenantId?: string }) {
    const res = await axios.get(`${ANALYTICS}/payments-statistics`, {
      params,
    })
    return res.data
  },

  async attendanceMarkedByStaff(params: { tenantId?: string }) {
    const res = await axios.get(`${ANALYTICS}/attendance-marked`, {
      params,
    })
    const mobile = res.data?.platforms
      ?.filter((item) => item.platformType === 'mobile')
      ?.reduce((acc, curr) => {
        return {
          totalCount: acc.totalCount ?? 0 + curr.totalCount,
          percentage: acc.percentage ?? 0 + curr.percentage,
          thisMonth: acc.thisMonth ?? 0 + curr.thisMonth,
          lastMonth: acc.lastMonth ?? 0 + curr.lastMonth,
          status: getStatusCondition(
            acc.thisMonth ?? 0 + curr.thisMonth,
            acc.lastMonth ?? 0 + curr.lastMonth,
          ),
        }
      }, {})

    const web = res.data.platforms?.find((item) => item.platformType === 'web')

    return {
      totalCount: res.data.totalCount,
      percentage: res.data.percentage,
      status: res.data.status,
      mobile: mobile,
      web: web,
    }
  },

  async fetchActiveUsersGraph(params: {
    dateRange: 'day' | 'month' | 'year' | 'week'
    startDate: string | Date
    endDate: string | Date
    userType?: 'staff_user' | 'student'
    tenantId?: string
  }) {
    const res = await axios.get(`${ANALYTICS}/daily-active-users`, {
      params: params,
    })
    return res.data
  },

  async fetchWhatsAppAnalytics() {
    const res = await axios.get(ANALYTICS + '/whatsapp-analytics')

    return res.data
  },

  async fetchFailedMessages(params: {
    clientName: string
    today?: boolean
    limit?: number
    offset?: number
  }) {
    const res = await axios.get(ANALYTICS + '/failed-messages', {
      params: params,
    })

    return res.data
  },

  async fetchActiveUsersList(params: {
    tenantId: string
    type?: 'staff_user' | 'student'
    limit: number
    offset: number
  }) {
    const res = await axios.get(ANALYTICS + '/active-user-list', {
      params: params,
    })

    return res.data
  },

  async fetchInactiveUsersList(params: {
    tenantId: string
    type?: 'staff_user' | 'student'
    limit: number
    offset: number
  }) {
    const res = await axios.get(ANALYTICS + '/inactive-user-list', {
      params: params,
    })

    return res.data
  },

  async fetchActivityWiseAnalytics(params: {
    tenantId?: string
    activityType: string[]
    offset?: number
    limit?: number
    userType: 'staff_user' | 'student'
    fromDate: Date | null
    toDate: Date | null
  }) {
    const res = await axios.get(ANALYTICS + '/activity-wise-analytics', {
      params: params,
    })

    return res.data
  },

  exportActivityWiseAnalytics: async (params: {
    tenantId?: string
    activityType: string[]
    userType: 'staff_user' | 'student'
    endDate: Date | null
    startDate: Date | null
  }) => {
    const data = await analytics.fetchActivityWiseAnalytics({
      tenantId: params.tenantId,
      activityType: params.activityType,
      userType: params.userType,
      fromDate: params.startDate,
      toDate: params.endDate,
    })

    const excelData = data?.data?.map((obj) => {
      return {
        'User Id': obj?.userId,
        username: obj?.username,
        'Full Name': obj?.fullName,
        Email: obj?.email,
        Mobile: obj?.workPhoneNumber,
        'User Type': obj?.type,
        'Tenant Name': obj?.tenantFullName,
      }
    })

    const fileName = `Activity Data - ${params?.startDate.toLocaleDateString()} to ${params.endDate.toLocaleDateString()}.xlsx`

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelData)
    const wb: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, ws, 'Activity Data')
    XLSX.writeFile(wb, fileName)
  },
}
