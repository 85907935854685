import axios from 'config/axios'

const TENANT_URL = '/analytics'

export const calendar = {
  async fetchAll() {
    const res = await axios.get(TENANT_URL + '/academic-calendar-timeline')
    return res.data
  },
}
