import { AxiosRequestConfig } from 'axios'
import axios from 'config/axios'

export const paymentTypes = {
  async fetchTypes(id: any) {
    const res = await axios.get(`/tenants/${id}`)
    return res.data
  },

  async createType(tenantId: any, data: any, postBody: any) {
    const config: AxiosRequestConfig = {
      method: data ? 'PUT' : 'POST',
      url: data
        ? `/tenants/${tenantId}/payment-credentials/${data?._id}`
        : `/tenants/${tenantId}/payment-credentials`,
      data: postBody,
    }

    return axios(config)
  },

  async deleteType(id: any, tenantId: any) {
    await axios.delete(`/tenants/${tenantId}/payment-credentials/${id}`)
  },
}
