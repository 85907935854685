import {
  ActionButton,
  Breadcrumbs,
  DeleteButton,
  DrawerButton,
  EditButton,
  PageContent,
  PageHeader,
  Table,
  useConfirm,
  ViewButton,
} from '@campxdev/shared'
import { Stack } from '@mui/material'
import axios from 'config/axios'
import { useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import CategoryForm from './CategoryForm'

export default function Categories() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { isConfirmed } = useConfirm()

  const { data, isLoading } = useQuery('categorys', () =>
    SERVICES.tenant.fetchAllTipsCategories(),
  )

  const columns = [
    {
      title: 'name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      key: 'type',
      dataIndex: 'type',
    },
    {
      title: 'Actions',
      key: 'tipe',
      dataIndex: 'tip',
      render: (celldata, row) => (
        <>
          <Stack gap={1.5} direction={'row'}>
            <ViewButton onClick={() => navigate(row?._id)} />
            <DrawerButton
              title="Edit Category"
              anchor={({ open }) => <EditButton onClick={open} />}
              content={({ close }) => (
                <CategoryForm data={row} hideDialog={close} />
              )}
            />
            <DeleteButton
              onClick={() => {
                handleDelete(row?._id)
              }}
            />
          </Stack>
        </>
      ),
    },
  ]

  const handleDelete = async (id: any) => {
    const confirmed = await isConfirmed(
      'Are you sure you want to delete the Category?',
    )

    if (!confirmed) return
    try {
      await axios.delete(`/tips-categories/${id}`)
      queryClient.invalidateQueries('categorys')
      toast.success('Category Deleted successfully')
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting Category')
    }
  }

  return (
    <div>
      <PageHeader
        title={
          <Breadcrumbs
            links={[
              { name: 'Home', to: '/' },
              { name: 'Categorys', to: null },
            ]}
          />
        }
        actions={[
          <DrawerButton
            anchor={({ open }) => (
              <ActionButton onClick={open}>Add Category</ActionButton>
            )}
            key="1"
            content={({ close }) => (
              <CategoryForm data={null} hideDialog={close} />
            )}
            title="Add New Category"
          />,
        ]}
      />
      <PageContent>
        <Table columns={columns} dataSource={data} loading={isLoading} />
      </PageContent>
    </div>
  )
}
