import { Image } from '@campxdev/shared'
import { Typography } from '@mui/material'
import campxLogoWhite from '../../assets/images/campx_logo_white.png'
import welcomeImage from '../../assets/images/welcomeimage.png'
import EmailLogin from './EmailLogin'
import campxLogoPrimary from '../../assets/images/campx_logo_primary.png'
import {
  StyledBanner,
  StyledBannerFooter,
  StyledBannerImage,
  StyledFormSection,
  StyledMain,
} from './styles'

const LoginPage = () => {
  return (
    <StyledMain>
      <StyledBanner>
        <StyledBannerImage>
          <img src={welcomeImage} alt="login-banner" />
        </StyledBannerImage>
        <StyledBannerFooter>
          <div className="logo">
            <Typography variant="body1" color="white">
              Powered By
            </Typography>
            <img src={campxLogoWhite} alt="login-banner" />
          </div>
          <div className="copyright">
            <Typography variant="subtitle1" color="white">
              &#169; All Rights reserved to CampX Edutech Pvt. Ltd., 2022
            </Typography>
          </div>
        </StyledBannerFooter>
      </StyledBanner>
      <StyledFormSection>
        <Logo />
        <EmailLogin />
      </StyledFormSection>
    </StyledMain>
  )
}

export default LoginPage

const Logo = () => {
  return (
    <Image alt="CampX" height="64px" width="180px" src={campxLogoPrimary} />
  )
}
