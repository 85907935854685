import {
  Box,
  Typography,
  TextField,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material'
import { MoreVert } from '@mui/icons-material'
import { useState } from 'react'
import { DropDownButton } from '@campxdev/shared'

const QuicksightUpdateComponent = ({ template }) => {
  return (
    <Box
      sx={{
        padding: '15px',
        border: '1px solid #12121285',
        borderRadius: '10px',
        margin: '25px',
      }}
    >
      <Typography variant="h6" mb={'20px'}>
        {template.name}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <TextField
          label="Template ID"
          variant="outlined"
          disabled
          value={template.templateId}
          sx={{ mr: 2 }}
        />

        <DropDownButton
          menu={[
            {
              label: 'Update Template',
              onClick: async () => {
                {
                  // handleMenuOpen
                }
              },
            },
            {
              label: 'Update All Dashboards',
              onClick: async () => {
                {
                  // handleMenuOpen
                }
              },
            },
          ]}
          icon={{
            icon: <MoreVert />,
            outlined: false,
          }}
        />
      </Box>
    </Box>
  )
}

export default QuicksightUpdateComponent
