import { analytics } from './analytics'
import { calendar } from './calendar'
import { dashboardManager } from './dashboardManager'
import { oauth_clients } from './oauth-clients'
import { paymentTypes } from './paymentTypes'
import { tenant } from './tenant'
import { timetable } from './timetable'
import { users } from './users'

export const SERVICES = {
  tenant,
  analytics,
  calendar,
  timetable,
  oauth_clients,
  users,
  paymentTypes,
  dashboardManager,
}
