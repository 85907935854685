import { axios, axiosErrorToast } from '@campxdev/shared'
import { InsertPhotoOutlined } from '@mui/icons-material'
import { Typography, styled } from '@mui/material'
import { ReactNode, useRef, useState } from 'react'

interface ImageUploadProps {
  onFileUploaded: (v) => void
  postUrl: string
  StyledUpload?: ReactNode
  loadingState?: (v) => void
  postBody?: { [key: string]: string }
}
export default function ImageUpload({
  onFileUploaded,
  postUrl,
  StyledUpload,
  loadingState,
  postBody = {},
}: ImageUploadProps) {
  const [loading, setLoading] = useState(false)
  const ref = useRef<HTMLInputElement>(null)

  const handleChange = (e) => {
    if (e.target.files[0]) {
      const formData = new FormData()
      formData.append('file', e.target.files[0])
      if (postBody) {
        Object.keys(postBody).forEach((key) => {
          formData.append(key, postBody[key])
        })
      }
      setLoading(true)
      loadingState && setLoading(true)
      axios
        .post(postUrl, formData)
        .then((res) => {
          onFileUploaded({
            mediaObject: res?.data?.mediaObject,
            url: res.data.url,
            key: res.data.key,
            fileName: res.data?.mediaObject?.originalFileName ?? '',
            id: res.data?.mediaObject?.id ?? '',
          })
          setLoading(false)
          loadingState && setLoading(false)
        })
        .catch((err) => {
          setLoading(false)
          loadingState && setLoading(false)
          axiosErrorToast(err)
        })
    }
  }

  return (
    <>
      {StyledUpload ? (
        <div
          onClick={() => {
            ref.current.click()
          }}
        >
          {StyledUpload}
        </div>
      ) : (
        <StyledImageLabel
          onClick={() => {
            ref.current.click()
          }}
        >
          <InsertPhotoOutlined />
          <Typography sx={{ opacity: '0.6' }}>
            {loading ? 'Uploading...' : `Upload Photo`}
          </Typography>
        </StyledImageLabel>
      )}

      <input
        accept="image/*"
        type="file"
        hidden
        ref={ref}
        onChange={handleChange}
      />
    </>
  )
}

export const StyledImageLabel = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  borderRadius: '10px',
  width: '100%',
  height: '150px',
  border: '1px dashed #0000001A',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#EFEFEF',
  },
  '& > *': {
    color: theme.palette.text.disabled,
  },
}))
