import axios from 'config/axios'

const TENANT_URL = '/tenants'

export const timetable = {
  async fetchAll(params: {
    fromDate: string
    toDate: string
    examinationType?: string
  }) {
    const res = await axios.get(
      `${TENANT_URL}/academic-calendar-exam-print-events`,
      { params },
    )

    return res.data
  },
}
