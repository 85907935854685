import { Box, Button } from '@mui/material'
import axios from 'config/axios'
import Cookies from 'js-cookie'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

const logout = async () => {
  return axios.post('/auth/logout')
}

export default function LogoutButton() {
  const { mutate, isLoading } = useMutation(logout, {
    onSuccess(data) {
      Cookies.remove('campx_admin_session_key')
      window.location.href = window.location.origin
    },
  })

  return (
    <Box sx={{ position: 'absolute', right: '20px', top: '20px' }}>
      <Button
        onClick={() => {
          mutate()
        }}
        disabled={isLoading}
        variant="outlined"
        sx={{ background: 'white', ':hover': { background: 'white' } }}
      >
        Super Admin Logout
      </Button>
    </Box>
  )
}

export function TipsButton() {
  const navigate = useNavigate()
  return (
    <Box sx={{ position: 'absolute', right: '250px', top: '20px' }}>
      <Button
        onClick={() => navigate('/tips')}
        variant="outlined"
        sx={{ background: 'white', ':hover': { background: 'white' } }}
      >
        AddTips
      </Button>
    </Box>
  )
}
