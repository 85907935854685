import {
  DropDownButton,
  FormDatePicker,
  FormSingleSelect,
  PageContent,
  PageHeader,
  Spinner,
  Table,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, styled } from '@mui/material'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import { useImmer } from 'use-immer'
import * as yup from 'yup'
import { exportReport } from './service'

const schema = yup.object().shape({
  fromDate: yup.date().nullable().required('From Date is required'),
  toDate: yup
    .date()
    .nullable()
    .required('To Date is required')
    .min(yup.ref('fromDate'), 'To Date cannot be before From Date'),
})

const StyledFiltersBox = styled(Box)({
  gap: '1rem',
  display: 'grid',
  gridTemplateColumns: '220px 220px 200px 150px',
  alignItems: 'flex-end',
  marginBottom: '3rem',
})

function AcademicTimetable() {
  const [state, setState] = useImmer({
    data: [],
    headers: [],
    fromDate: null,
    toDate: null,
    examinationType: '',
  })

  const columns = state.headers.map((header) => ({
    title: header.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
    dataIndex: header,
    key: header,
  }))

  const { handleSubmit, control } = useForm({
    defaultValues: {
      fromDate: null,
      toDate: null,
      examinationType: '',
    },
    resolver: yupResolver(schema),
  })

  const { isLoading } = useQuery(
    ['academic-timetable', state.fromDate, state.toDate, state.examinationType],
    () =>
      SERVICES.timetable.fetchAll({
        fromDate: state.fromDate,
        toDate: state.toDate,
        ...(state.examinationType && {
          examinationType: state.examinationType,
        }),
      }),
    {
      onSuccess: (res) => {
        setState((s) => {
          s.data = res?.data || []
          s.headers = res?.headers || []
        })
      },
      enabled: !!state.fromDate && !!state.toDate,
    },
  )

  const onSubmit = (formData) => {
    setState((s) => {
      s.fromDate = moment(formData.fromDate).format('YYYY-MM-DD')
      s.toDate = moment(formData.toDate).format('YYYY-MM-DD')
      s.examinationType = formData.examinationType
    })
  }

  return (
    <>
      <PageHeader
        title="Academic Timetable"
        actions={
          <DropDownButton
            key="export-dropdown"
            button={{
              buttonProps: { variant: 'outlined' },
              label: 'Export As',
            }}
            menu={[
              {
                label: 'Export as XLSX',
                onClick: () => handleExport(state, 'xlsx'),
              },
              {
                label: 'Export as PDF',
                onClick: () => handleExport(state, 'pdf'),
              },
            ]}
          />
        }
      />
      <PageContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Filters control={control} />
        </form>
        {isLoading ? (
          <Spinner />
        ) : (
          <Table columns={columns} dataSource={state.data} />
        )}
      </PageContent>
    </>
  )
}

const Filters = ({ control }) => {
  return (
    <StyledFiltersBox>
      <FormDatePicker label="From Date" control={control} name="fromDate" />
      <FormDatePicker label="To Date" control={control} name="toDate" />
      <FormSingleSelect
        label="Examination Type"
        control={control}
        name="examinationType"
        options={[
          { label: 'External', value: 'external' },
          { label: 'Internal', value: 'internal' },
        ]}
      />
      <Button type="submit" sx={{ height: '50px' }}>
        Submit
      </Button>
    </StyledFiltersBox>
  )
}

function handleExport(state, format) {
  if (!state.fromDate || !state.toDate) {
    toast.error('Please select From Date and To Date')
    return
  }
  if (state.data.length === 0) {
    toast.error('No data found')
    return
  }
  if (format === 'pdf') {
    const params = {
      fromDate: moment(state.fromDate).format('YYYY-MM-DD'),
      toDate: moment(state.toDate).format('YYYY-MM-DD'),
      ...(state.examinationType && {
        examinationType: state.examinationType,
      }),
    }
    window.open(
      `print/academic-timetable?${new URLSearchParams(params).toString()}`,
    )
  } else {
    exportReport(state)
  }
}

export default AcademicTimetable
