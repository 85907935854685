import {
  ActionButton,
  CardsGrid,
  PageContent,
  PageHeader,
  Spinner,
} from '@campxdev/shared'
import { Box, styled } from '@mui/material'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { SERVICES } from 'services'
import { ClientCard } from './ClientCard'

function Clients() {
  const navigate = useNavigate()
  const { data, isLoading, isRefetching } = useQuery('tenants', () =>
    SERVICES.tenant.fetchAll(),
  )

  if (isLoading) return <Spinner />
  return (
    <>
      <PageHeader
        title={'Clients'}
        actions={[
          <ActionButton
            variant="outlined"
            onClick={() => navigate('register')}
            key={'1'}
          >
            Add New
          </ActionButton>,
        ]}
      />
      <PageContent>
        <CardsGrid cardWidth={400} gap={20}>
          {data?.result?.map((item, index) => (
            <ClientCard
              key={index}
              _id={item._id}
              name={item.name}
              subDomain={item.subDomain}
              twoFactorAuthentication={item.twoFactorAuthEnabled}
            />
          ))}
        </CardsGrid>
      </PageContent>
    </>
  )
}

export default Clients

const StyledBanner = styled(Box)(({ theme }) => ({
  height: '150px',
  background: `linear-gradient(to right,#1E28F5, #149ECD)`,
  width: '100%',
  display: 'grid',
  placeItems: 'center',
  color: 'white',
  position: 'relative',
  '& .MuiTypography-root': {
    color: 'white',
    fontSize: '20px',
  },
}))
