import { PageNotFound, Permission } from '@campxdev/shared'
import AcademicCalendar from 'pages/AcademicCalendar'
import AcademicTimetable from 'pages/AcademicTimetable/AcademicTimetable'
import PrintAcademicTimetable from 'pages/AcademicTimetable/PrintAcademicTimetable'
import Admissions from 'pages/Admissions'
import MobileApps from 'pages/Apps/'
import { Reports } from 'pages/Attendance'
import { BirdsEyeViewDashboard } from 'pages/BirdsEyeView'
import {
  ActiveUserList,
  AllStudents,
  InactiveUserList,
  UserList,
} from 'pages/BirdsEyeView/UserList'
import Classwork from 'pages/Classwork'
import Faculty from 'pages/FacultyReport/Faculty'
import FacultyReport from 'pages/FacultyReport/FacultyReport'
import Hostel from 'pages/Hostel'
import OAuth from 'pages/OAuthClients/OAuthClients'
import PaymentFeeCharges from 'pages/PaymentFeeCharges'
import PaymentTypes from 'pages/PaymentTypes/PaymentTypes'
import Payments from 'pages/Payments'
import Clients from 'pages/SuperAdmin/Clients/Clients'
import TenantCreation from 'pages/SuperAdmin/Clients/TenantCreation'
import TenantUpdate from 'pages/SuperAdmin/Clients/TenantUpdate'
import { FrappeeConfig } from 'pages/SuperAdmin/FrapeeConfig/FrappeeConfig'
import QuicksightTemplates from 'pages/SuperAdmin/QuicksightTemplates/QuicksightTemplates'
import Categorys from 'pages/SuperAdmin/Tips/Categories/Categories'
import TipsDetails from 'pages/SuperAdmin/Tips/Tips/TipsDetails'
import WhatsappAnalytics from 'pages/Whatsapp/WhatsappAnalytics'
import { Navigate } from 'react-router-dom'

export const baseRoutes = [
  {
    index: true,
    element: <Navigate to="dashboard" />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'dashboard',
    children: [
      {
        index: true,
        element: <BirdsEyeViewDashboard />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
      {
        path: 'user-lists',
        element: <UserList />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
        children: [
          {
            index: true,
            element: <Navigate to="inactive-users" />,
          },
          {
            path: 'active-users',
            element: <ActiveUserList />,
            permissionKey: Permission.CAN_DASHBOARD_VIEW,
          },
          {
            path: 'inactive-users',
            element: <InactiveUserList />,
            permissionKey: Permission.CAN_DASHBOARD_VIEW,
          },
        ],
      },
    ],
  },
  {
    path: 'reports',
    element: <Reports />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'classwork',
    element: <Classwork />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'admissions',
    element: <Admissions />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'clients',
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
    children: [
      {
        index: true,
        element: <Clients />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
      {
        path: 'register',
        element: <TenantCreation />,
        permissionKey: Permission.CAN_DASHBOARD_VIEW,
      },
    ],
  },
  {
    path: 'quicksight-templates',
    element: <QuicksightTemplates />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'payments',
    element: <Payments />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'hostel',
    element: <Hostel />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'apps',
    element: <MobileApps />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'whatsapp-analytics',
    element: <WhatsappAnalytics />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'tips',
    element: <Categorys />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },

  {
    path: 'academic-calendar',
    element: <AcademicCalendar />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'download-faculty-report',
    element: <FacultyReport />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'tips/:id',
    element: <TipsDetails />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'students',
    element: <AllStudents />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'faculty',
    element: <Faculty />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'academic-timetable',
    element: <AcademicTimetable />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: 'oauth-clients',
    element: <OAuth />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: '*',
    element: <PageNotFound />,
  },
]

export const configurationRoutes = [
  {
    path: '/config/clients/:id',
    element: <TenantUpdate />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: '/config/clients/frappee-config/:id',
    element: <FrappeeConfig />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: '/config/payment-types/:id',
    element: <PaymentTypes />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
  {
    path: '/config/payment-types/:id/fee-charges',
    element: <PaymentFeeCharges />,
    permissionKey: Permission.CAN_DASHBOARD_VIEW,
  },
]

export const printRoutes = [
  {
    path: 'academic-timetable',
    element: <PrintAcademicTimetable />,
  },
]
