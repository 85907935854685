import { axios } from '@campxdev/shared'
export const fetchData = (params) =>
  axios
    .get('/app-themes/', {
      params: {
        ...params,
      },
    })
    .then((res) => res.data)

export const createApp = (postBody) => {
  return axios.post(`/app-themes/`, postBody).then((res) => res.data)
}
