export const paymentTypes = {
  BILL_DESK: 'BILL_DESK',
  BILL_DESK_V2: 'BILL_DESK_V2',
  AIRPAY: 'AIRPAY',
  PAYTM: 'PAYTM',
  RAZORPAY: 'RAZORPAY',
  GRAY_QUEST: 'GRAY_QUEST',
}

export const configTypes = {
  PAYTM: 'paytmConfig',
  RAZORPAY: 'razorpayConfig',
  AIRPAY: 'airPayConfig',
  BILL_DESK: 'billDeskConfig',
  BILL_DESK_V2: 'billDeskV2Config',
  GRAY_QUEST: 'grayQuestConfig',
}
