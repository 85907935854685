import { ErrorBoundary, PermissionsStore, Spinner } from '@campxdev/shared'
import { styled } from '@mui/material'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import AppHeader from './AppHeader'

const adminCookie = Cookies.get('campx_admin_session_key')

const headerHeight = '64px'
export const StyledMainContentContainer = styled('main')(() => ({
  width: '100%',
  height: `calc(100vh - ${headerHeight})`,
  position: 'fixed',
  top: headerHeight,
  overflowY: 'auto',
}))

export const StyledHeaderContainer = styled('header')(() => ({
  zIndex: 300,
  width: '100%',
  position: 'fixed',
  top: 0,
  left: 0,
  minHeight: headerHeight,
  background: '#fff',
  boxShadow: '0px 0px 3px #0000001a',
  '& > div': {
    height: headerHeight,
  },
}))

export default function AppLayout() {
  const navigate = useNavigate()
  const { permissions } = PermissionsStore.useState((s) => s)

  useEffect(() => {
    if (!adminCookie) {
      navigate('/login')
    } else {
      PermissionsStore.update((s) => {
        s.permissions = {
          ...s.permissions,
          can_dashboard_view: 1,
        } as any
      })
    }
  }, [])

  if (!permissions) return <Spinner />
  return (
    <>
      <ErrorBoundary>
        <StyledHeaderContainer>
          <AppHeader />
        </StyledHeaderContainer>
        <StyledMainContentContainer>
          <ErrorBoundary>
            <Outlet />
          </ErrorBoundary>
        </StyledMainContentContainer>
      </ErrorBoundary>
    </>
  )
}
