import { axiosErrorToast, isDevelopment, UserBox } from '@campxdev/shared'
import {
  StyledHeader,
  StyledImageWrapper,
  StyledLogosWrapper,
  StyledRouterLink,
} from '@campxdev/shared/src/components/Layout/Header/styles'
import { ExitToAppOutlined } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import axios from 'config/axios'
import Cookies from 'js-cookie'
import { collegex } from './Header/assets'
import CogWheelMenu from './Header/CogWheelMenu'

const logout = async () => {
  axios({
    method: 'POST',
    baseURL: process.env.REACT_APP_API_HOST,
    url: '/auth/logout',
  })
    .then((res) => {
      Cookies.remove('campx_tenant')
      Cookies.remove('campx_session_key')
      Cookies.remove('campx_admin_session_key')
      window.location.href = window.location.origin
    })
    .catch((err) => {
      axiosErrorToast('Unable To Logout.')
    })
}

function AppHeader() {
  return (
    <StyledHeader>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <AppLogo />
      </Box>
      <Box className="actions">
        <UserBox
          actions={[]}
          customActions={[
            {
              label: 'Logout',
              icon: <ExitToAppOutlined />,
              onClick: logout,
            },
          ]}
          fullName={'Admin'}
        />
      </Box>
    </StyledHeader>
  )
}

export default AppHeader

const AppLogo = () => {
  return (
    <StyledRouterLink to={'/'}>
      <StyledLogosWrapper>
        <StyledImageWrapper>
          <img src={collegex} />
        </StyledImageWrapper>
        <Box
          sx={{
            height: '26px',
            width: '2px',
            background: 'gray',
          }}
        ></Box>
        <StyledImageWrapper>
          {isDevelopment ? (
            <Typography variant="h1">Admin</Typography>
          ) : (
            <img
              src={collegex}
              onError={(e: any) => {
                e.target.src = collegex
              }}
            />
          )}
        </StyledImageWrapper>
      </StyledLogosWrapper>
    </StyledRouterLink>
  )
}
