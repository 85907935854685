import {
  ActionButton,
  axios,
  FormSingleSelect,
  FormTextField,
  Media,
  MediaRow,
  SingleCheckbox,
  UploadButton,
} from '@campxdev/shared'
import { yupResolver } from '@hookform/resolvers/yup'
import { Stack, Typography } from '@mui/material'
import { AxiosRequestConfig } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import * as yup from 'yup'

const schema = yup.object().shape({
  name: yup.string().required('Name is required'),
  type: yup.string().required('Type is reaquired'),
})
function CategoryForm({
  data,
  hideDialog,
}: {
  data?: any
  hideDialog: () => void
}) {
  const [tip, setTip] = useState(false)
  const [logo, setLogo] = useState<Media[]>([])
  const [image, setImage] = useState<Media[]>([])
  useEffect(() => {
    if (!data) return
    setLogo([
      {
        key: data?.iconKey,
        url: data?.iconKey,
        type: 'image',
      },
    ])
    setImage([
      {
        key: data?.tips[0]?.imageKey,
        url: data?.tips[0]?.imageKey,
        type: 'image',
      },
    ])
  }, [data])

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      ...data,
      iconKey: data?.iconKey,
      title: data?.tips[0]?.title,
      imageKey: data?.tips[0]?.imageKey,
      description: data?.tips[0]?.description,
    },
  })
  const handleLogoDelete = (mediaKey) => {
    setLogo(logo?.filter((item) => item.key !== mediaKey))
  }
  const handleDelte = (mediaKey) => {
    setImage(image?.filter((item) => item.key !== mediaKey))
  }
  const handleImageChange = (mediaObj) => {
    setImage((prev) => [...prev, mediaObj])
  }
  const handleLogoChange = (mediaObj) => {
    setLogo((prev) => [...prev, mediaObj])
  }
  const queryClient = useQueryClient()

  const onSubimt = async (formdata) => {
    const postbody = {
      name: formdata.name,

      type: formdata.type,
      iconKey: logo[0].url,
      tips: tip
        ? [
            {
              title: formdata.title,
              description: formdata.description,
              imageKey: image[0]?.url,
            },
          ]
        : [],
    }
    const config: AxiosRequestConfig = {
      method: data ? 'PUT' : 'POST',
      url: data ? `/tips-categories/${data._id}` : '/tips-categories',
      data: postbody,
    }
    try {
      await axios(config)
      hideDialog()
      toast.success('Category saced successfully')
      queryClient.invalidateQueries('categorys')
    } catch (error) {
      console.log(error)
      toast.error(error.response.data.message ?? 'Error saving Category')
    }
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubimt)}>
        <Stack gap={2.5}>
          <FormTextField
            name="name"
            label={'Category name'}
            control={control}
            required
          />

          <Stack direction={'row'} gap={0.4}>
            <Typography sx={{ opacity: '0.58' }}>Icon</Typography>
            <Typography sx={{ color: '#f5222d' }}>*</Typography>
          </Stack>
          {!logo?.length ? (
            <UploadButton
              type="image"
              onChange={handleLogoChange}
              uploadUrl="/tips-categories/upload-image"
              refetchFn={() => {}}
            />
          ) : (
            <>
              <MediaRow list={logo} onDelete={handleLogoDelete} />
            </>
          )}
          <FormSingleSelect
            control={control}
            name="type"
            label="Type"
            options={[
              { label: 'Faculty', value: 'Faculty' },
              { label: 'Student', value: 'Student' },
            ]}
          />
          {!data && (
            <SingleCheckbox
              checked={tip || data?.tips.length}
              label="Add Tip"
              onChange={(e) => setTip(!tip)}
            />
          )}

          {tip && !data && (
            <Stack gap={2.5}>
              <FormTextField
                name="title"
                label={'Title'}
                control={control}
                required
              />
              <FormTextField
                control={control}
                name="description"
                label={'Description'}
                required
              />
              <Stack direction={'row'} gap={0.4}>
                <Typography sx={{ opacity: '0.58' }}> Image</Typography>
                <Typography sx={{ color: '#f5222d' }}>*</Typography>
              </Stack>
              {!image?.length ? (
                <UploadButton
                  type="image"
                  onChange={handleImageChange}
                  uploadUrl="/tips-categories/upload-image"
                  refetchFn={() => {}}
                />
              ) : (
                <>
                  <MediaRow list={image} onDelete={handleDelte} />
                </>
              )}
            </Stack>
          )}
        </Stack>
        <Stack direction="row" gap={2} mt={2}>
          <ActionButton type="submit" fullWidth>
            {data ? 'Edit Category' : 'Add Category'}
          </ActionButton>
          <ActionButton
            variant="outlined"
            fullWidth
            onClick={() => hideDialog()}
          >
            Cancel
          </ActionButton>
        </Stack>
      </form>
    </>
  )
}

export default CategoryForm
