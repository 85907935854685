import { AccordionSummary, Box, styled } from '@mui/material'

export const StyledFeeTypeLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '10px',
  justifyContent: 'space-between',
  border: '1px solid #1212121A',
  borderRadius: '10px',
  padding: '10px',
  '& .MuiTypography-root': {
    flexShrink: 0,
  },
  '& .MuiTypography-body1': {
    fontSize: '15px',
  },
}))

export const StyledAccordianSummary = styled(AccordionSummary)(({ theme }) => ({
  border: '1px solid #1212121A',
  // borderRadius: '10px',
  // backgroundColor: '#1212121A',
}))

export const StyledFlexBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
})
