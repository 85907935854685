import { useConfirm } from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { ChevronRight } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Switch,
  Typography,
  styled,
} from '@mui/material'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { SERVICES } from 'services'

export const ClientCard = (data) => {
  const { isConfirmed } = useConfirm()
  const navigate = useNavigate()
  const { name, _id, subDomain, twoFactorAuthentication } = data

  const { mutate, isLoading } = useMutation(
    SERVICES.tenant.twoFactorAuthentication,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('tenants')
      },
    },
  )

  const handleSwitchChange = async (event) => {
    const value = event.target.checked
    const confirmed = await isConfirmed(
      `Are you sure you want to turn ${
        twoFactorAuthentication ? 'OFF' : 'ON'
      } two-factor authentication?`,
    )
    if (!confirmed) return
    mutate({
      tenantId: _id,
      twoFactorAuthentication: value,
    })
  }

  return (
    <Card>
      <CardHeader
        sx={{ minHeight: '120px' }}
        title={name}
        subheader={subDomain}
      />
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="subtitle1">Two Factor Authentication</Typography>
        <Switch
          checked={twoFactorAuthentication}
          onChange={handleSwitchChange}
        />
      </CardContent>

      <StyledCardActions>
        <Button
          variant="text"
          fullWidth
          onClick={() => {
            navigate(`/config/clients/${_id}`)
          }}
          endIcon={<ChevronRight />}
          sx={{ fontSize: '14px' }}
        >
          Edit
        </Button>
      </StyledCardActions>
    </Card>
  )
}

const StyledCardActions = styled(CardActions)(({ theme }) => ({
  borderTop: theme.borders.grayLight,
  '& .MuiButton-root': {
    justifyContent: 'space-between',
    height: '40px',
    padding: '20px',
  },
}))
