import axios from 'config/axios'

const TENANT_URL = '/dashboard-manager'

export const dashboardManager = {
  async getAll() {
    const res = await axios.get(TENANT_URL)
    return res.data
  },
}
