import { JsonPreview, Spinner } from '@campxdev/shared'
import { useQuery } from 'react-query'
import { SERVICES } from 'services'

export default function AcademicCalendar() {
  const { data, isLoading } = useQuery(
    'academic-calendar',
    SERVICES.calendar.fetchAll,
  )

  if (isLoading) return <Spinner />
  return (
    <div>
      <JsonPreview data={data} />
    </div>
  )
}
