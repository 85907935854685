import {
  ActionButton,
  Breadcrumbs,
  CardsGrid,
  DrawerButton,
  PageContent,
  PageHeader,
  Spinner,
} from '@campxdev/shared'
import { Box, styled, Typography } from '@mui/material'
import axios from 'config/axios'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import CategoryTipsForm from './CategoryTipForm'
import TipsCard from './TipsCard'

function TipsDetails() {
  const { id } = useParams()

  const { data, isLoading } = useQuery('categoriesData', () =>
    axios.get(`/tips-categories/${id}`).then((res) => res.data),
  )

  if (isLoading) return <Spinner />
  return (
    <>
      <PageHeader
        title={[
          <Breadcrumbs
            key={'1'}
            links={[
              { name: 'Categories', to: '/tips' },
              { name: `${data?.name}`, to: null },
            ]}
          />,
        ]}
        actions={[
          <DrawerButton
            anchor={({ open }) => (
              <ActionButton onClick={open}>Add Tip</ActionButton>
            )}
            key="2"
            content={({ close }) => (
              <CategoryTipsForm data={null} hideDialog={close} />
            )}
            title="Add New Tips"
          />,
        ]}
      />
      <PageContent>
        <CardsGrid cardWidth={300} gap={20}>
          {data?.tips?.map((item, index) => (
            <TipsCard data={item} key={index} />
          ))}
        </CardsGrid>
        {!data?.tips.length && (
          <StyledBox>
            <Typography variant="h3">
              Category-Tips Not Available for this page
            </Typography>
          </StyledBox>
        )}
      </PageContent>
    </>
  )
}

export default TipsDetails

const StyledCardContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '20px',
  flexWrap: 'wrap',
}))
const StyledBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '80vh',
  width: '100%',
}))
