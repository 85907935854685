import {
  ConfirmContextProvider,
  DialogProvider,
  GlobalNetworkLoadingIndicator,
  MuiThemeProvider,
  QueryClientProvider,
  ToastContainer,
} from '@campxdev/shared'
import { BrowserRouter } from 'react-router-dom'

export default function Providers({ children }) {
  return (
    <BrowserRouter>
      <QueryClientProvider>
        <MuiThemeProvider>
          <ConfirmContextProvider>
            <DialogProvider>
              {children}
              <GlobalNetworkLoadingIndicator />
              <ToastContainer />
            </DialogProvider>
          </ConfirmContextProvider>
        </MuiThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  )
}
