import Axios from 'axios'
import Cookies from 'js-cookie'

const sessionKey = Cookies.get('campx_admin_session_key')

let axios = Axios.create({
  baseURL: process.env.REACT_APP_API_HOST,
  withCredentials: true,
  headers: {
    ...(sessionKey && {
      campx_admin_session_key: sessionKey,
    }),
  },
})

export default axios
