import {
  Card,
  DropDownButton,
  Spinner,
  useConfirm,
  useModal,
} from '@campxdev/shared'
import { queryClient } from '@campxdev/shared/src/contexts/QueryClientProvider'
import { MoreVert } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { configTypes, paymentTypes } from 'constants/paymentTypes'
import { fetchFeeCharges } from 'pages/PaymentFeeCharges/service'
import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { SERVICES } from 'services'
import PaymentTypeForm from './PaymentTypeForm'

function PaymentTypes({}) {
  const { id } = useParams()
  const modal = useModal()
  const navigate = useNavigate()

  const { data, isLoading } = useQuery('payment-types', () =>
    SERVICES.paymentTypes.fetchTypes(id),
  )

  if (isLoading) return <Spinner />

  function addPayment() {
    modal({
      title: 'Add Payment Configuration',
      content: ({ close }) => (
        <PaymentTypeForm hideDialog={close} tenantId={id} />
      ),
    })
  }

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          margin: '10px',
          gap: '10px',
        }}
      >
        <Button
          variant="contained"
          onClick={() => {
            navigate('fee-charges')
          }}
        >
          Payment Fee Charges
        </Button>
        <Button variant="outlined" onClick={() => addPayment()}>
          + Add Payment Method
        </Button>
      </Box>
      <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
        {data?.paymentCredentials?.map((item, index) => (
          <Box sx={{ margin: '20px', width: '300px' }} key={index}>
            <PaymentCard
              item={item}
              id={
                item.type == paymentTypes.RAZORPAY
                  ? item[configTypes[item.type]]?.key
                  : item[configTypes[item.type]]?.merchantId ||
                    item[configTypes[item.type]]?.clientId
              }
              tenant={id}
              defaultId={data.defaultPaymentCredentials}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export const PaymentCard = ({ item, id, tenant, defaultId }) => {
  const modal = useModal()
  const { data: paymentCharges, isLoading } = useQuery(
    'get-payment-fee-charges',
    () => fetchFeeCharges(tenant),
  )
  const Data = paymentCharges?.find(
    (feeType) => feeType.id == item.paymentFeeChargesId,
  )

  const { isConfirmed } = useConfirm()

  function handleEditPaymentType(item) {
    modal({
      title: 'Payment Configuration',
      content: ({ close }) => (
        <PaymentTypeForm
          data={item}
          tenantId={tenant}
          hideDialog={close}
          defaultId={defaultId}
        />
      ),
    })
  }
  const handleDeletePaymentType = async (id) => {
    const confirmed = await isConfirmed(
      'Are you sure you want to delete the Payment Type?',
    )
    if (!confirmed) return

    try {
      await SERVICES.paymentTypes.deleteType(id, tenant)
      queryClient.invalidateQueries('payment-types')
      toast.success('Payment Type deleted successfully')
    } catch (error) {
      toast.error(error.response?.data?.message || 'Error deleting Type')
    }
  }

  return (
    <Card
      title={item.type}
      moreActions={
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <DropDownButton
            menu={[
              {
                label: 'Edit',
                onClick: () => {
                  handleEditPaymentType(item)
                },
              },
              {
                label: 'Delete',
                onClick: () => {
                  handleDeletePaymentType(item._id)
                },
              },
            ]}
            icon={{
              icon: <MoreVert />,
              outlined: false,
            }}
          />
        </Box>
      }
    >
      <Typography>{id}</Typography>
      <Typography sx={{ marginTop: '20px' }}>{Data?.name}</Typography>
    </Card>
  )
}

export default PaymentTypes
